@charset "UTF-8";
* {
  font-family: sans-serif;
}

/**=====================
    Admin CSS Start
==========================**/
p {
  font-size: 14px;
  color: #777777;
  line-height: 1;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 24px;
}

h3 {
  font-size: 24px;
  font-weight: 400;
  color: #777777;
  letter-spacing: 0.03em;
}

h2 {
  font-size: 36px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h4 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  letter-spacing: 0.05em;
}

del {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400;
}

@keyframes sidebar-menu-open {
  0% {
    transform: translate(0px, -50%) scale(1, 0);
  }
  100% {
    transform: translate(0px, 0%) scale(1, 1);
  }
}
.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgba(49, 49, 49, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  color: #ffffff;
  font: 10px Nunito, sans-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid rgba(49, 49, 49, 0.75);
  box-sizing: content-box;
  z-index: 10000;
  border-radius: 5px;
}
.chart-sparkline .jqsfield {
  color: #ffffff;
  font: 10px Nunito, sans-serif;
  text-align: left;
}

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 1rem 0 0;
}
.checkbox_animated:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 0.225rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.375rem;
  border: 2px solid #3b82f6;
  border-top-style: none;
  border-right-style: none;
}
.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.3rem;
  height: 1.3rem;
  background: #ffffff;
  border: 2px solid #e8ebf2;
  cursor: pointer;
}
.checkbox_animated:checked:before {
  transform: rotate(-45deg) scale(1, 1);
}

.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
}
.radio_animated:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: #3b82f6;
  border-radius: 50%;
}
.radio_animated:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #ffffff;
  border: 2px solid #e8ebf2;
  border-radius: 50%;
}
.radio_animated:checked:before {
  transform: scale(1, 1);
}

a:hover {
  color: #3b82f6;
  text-decoration: none;
}

.btn-popup {
  margin-bottom: 30px;
}

.media-table table tr th:last-child,
.media-table table tr td:last-child {
  text-align: left !important;
}

.ngx-charts {
  float: none !important;
}

.imgTable {
  width: 50px;
  height: 50px;
}

input[type=file] {
  height: 45px !important;
}

.btn.calendar {
  background-color: #dee2e6;
  padding: 7px 12px !important;
}

.input-grp-p {
  padding: 0;
}

.order-graph .ct-4 x-chartist svg {
  height: 450px !important;
}

ngb-datepicker .ngb-dp-navigation-select .custom-select:first-child {
  margin-right: 15px;
}
ngb-datepicker .ngb-dp-today {
  color: #000000 !important;
}
ngb-datepicker .ngb-dp-weekday {
  color: #000000;
  font-style: normal !important;
}
ngb-datepicker .ngb-dp-weekdays {
  border-bottom: none !important;
}
ngb-datepicker .ngb-dp-arrow .btn-light,
ngb-datepicker .ngb-dp-day .btn-light,
ngb-datepicker .ngb-dp-week-number .btn-light,
ngb-datepicker .ngb-dp-weekday .btn-light {
  font-size: 14px;
}
ngb-datepicker .ngb-dp-arrow .btn-link,
ngb-datepicker .ngb-dp-day .btn-link,
ngb-datepicker .ngb-dp-week-number .btn-link,
ngb-datepicker .ngb-dp-weekday .btn-link {
  color: #3b82f6;
}
ngb-datepicker .ngb-dp-day .btn-light {
  background-color: #ffffff !important;
}
ngb-datepicker .ngb-dp-day .bg-primary {
  background-color: #3b82f6 !important;
}

.ngb-dp-header,
.ngb-dp-weekdays.bg-light {
  background-color: #ffffff !important;
}

.vendor-list img {
  margin-right: 20px;
  height: 40px;
  width: auto !important;
  border-radius: 100%;
}

.user-image .imgTable {
  border-radius: 100%;
}

.menu-list-table table tr th,
.menu-list-table table tr td,
.user-image table tr th,
.user-image table tr td {
  text-align: center;
}

.media-datatable table tr th,
.media-datatable table tr td {
  text-align: left !important;
}
.media-datatable table tr th:nth-child(2), .media-datatable table tr th:first-child,
.media-datatable table tr td:nth-child(2),
.media-datatable table tr td:first-child {
  text-align: center !important;
}

.ngx-datatable .datatable-body-cell {
  padding: 0.5rem 0.75rem;
}

ngx-datatable .datatable-header {
  padding: 0.75rem;
}
ngx-datatable.bootstrap .datatable-header,
ngx-datatable.bootstrap .datatable-body {
  border: 1px solid #e8efff !important;
}

ng2-smart-table table {
  border: 1px solid #e8efff;
}
ng2-smart-table table .ng2-smart-titles {
  background-color: #f1f4fb;
}
ng2-smart-table table .ng2-smart-titles .ng2-smart-th {
  padding: 0.75rem;
}
ng2-smart-table table .ng2-smart-sort-link {
  color: #000000;
}
ng2-smart-table table .ng2-smart-sort-link:hover {
  text-decoration: none;
}
ng2-smart-table table .ng2-smart-action {
  font-size: 0;
}
ng2-smart-table table .ng2-smart-action:before {
  font-family: FontAwesome;
  font-size: 17px;
}
ng2-smart-table table .ng2-smart-action:hover {
  text-decoration: none;
}
ng2-smart-table table .ng2-smart-action-add-add:before {
  content: "\f067";
  color: #22af47;
  font-size: 19px;
}
ng2-smart-table table .ng2-smart-action-edit-edit:before {
  content: "\f040";
  color: #22af47;
}
ng2-smart-table table .ng2-smart-action-edit-save:before {
  content: "\f021";
  color: #4466f2;
}
ng2-smart-table table .ng2-smart-action-edit-cancel:before {
  content: "\f00d";
  color: #ff5370;
}
ng2-smart-table table .ng2-smart-action-delete-delete:before {
  content: "\f1f8";
  color: #ff5370;
}
ng2-smart-table table .ng2-smart-action-custom-custom:before {
  content: "\f06e";
  color: #0d3e7e;
  font-size: 19px;
}
ng2-smart-table table tr th,
ng2-smart-table table tr td {
  border: 1px solid #e8efff;
}
ng2-smart-table table tr th:last-child,
ng2-smart-table table tr td:last-child {
  text-align: center;
}
ng2-smart-table table tr:nth-child(even) td {
  background-color: rgba(241, 244, 251, 0.5);
}
ng2-smart-table table tr:nth-child(odd):hover td {
  background-color: rgba(241, 244, 251, 0.5);
}
ng2-smart-table ng2-smart-table-pager {
  margin-top: 30px;
  justify-content: flex-end !important;
}
ng2-smart-table .ng2-smart-pagination .page-link-next,
ng2-smart-table .ng2-smart-pagination .page-link-prev {
  font-size: 14px !important;
}

.page-item .page-link {
  color: #3b82f6;
}
.page-item .page-link:hover {
  text-decoration: none;
}
.page-item.active .page-link {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.single-item .owl-theme .owl-dots .owl-dot.active span, .single-item .owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
}
.single-item .owl-theme .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.5);
}

.auth-form .form-control {
  border-radius: 25px;
  padding: 20px 25px;
  border: 1px solid #eaeaea;
}
.auth-form .form-control.error {
  border-color: #ff3c7e;
  background-color: #ffeff4;
}
.auth-form .form-control.valid {
  border-color: #37c936;
  background-color: #ebfaeb;
  color: #278d26;
}

.errorRed {
  color: #ff3c7e;
  padding-top: 10px;
}

.card-left {
  z-index: 1;
}

.card-right {
  margin-left: -15px;
}
.card-right .card {
  padding-left: 15px;
}

.authentication-box {
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authentication-box .back-btn svg {
  position: absolute;
  left: 30px;
  height: 18px;
}
.authentication-box .row {
  align-items: center;
}
.authentication-box .tab2-card .nav-tabs .nav-link {
  font-size: 18px;
}
.authentication-box .form-footer {
  position: relative;
  padding-top: 25px;
  border-top: 1px solid #f3f3f3;
  margin-top: 25px;
}
.authentication-box .form-footer .social {
  margin-bottom: 0 !important;
  border-bottom: none;
}
.authentication-box .form-footer .social li {
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #3b82f6;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
}
.authentication-box .form-footer > span {
  position: absolute;
  top: -11px;
  padding-right: 20px;
  background-color: #ffffff;
  color: #909090;
}
.authentication-box .back-btn {
  float: right;
  margin: 0;
  font-size: 14px;
  position: relative;
  padding-left: 60px !important;
}
.authentication-box .btn-primary {
  border-radius: 25px;
  margin-top: 20px;
  font-weight: 400;
  padding: 11px 45px;
}
.authentication-box .container {
  max-width: 900px;
}
.authentication-box .container .form-group {
  margin-bottom: 1.5rem;
}
.authentication-box .container p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
}
.authentication-box .container h3 {
  color: #ffffff;
  font-weight: 600;
  text-align: center;
}
.authentication-box .container .bg-primary {
  padding: 50px;
  background-position: center;
  box-shadow: 1px 5px 24px 0 rgba(255, 128, 132, 0.8);
}
.authentication-box .container .svg-icon {
  padding: 24px;
  margin: 0 auto;
  border: 2px dashed #ffffff;
  border-radius: 100%;
  height: 130px;
  width: 130px;
  margin-bottom: 40px;
}
.authentication-box .container .svg-icon svg {
  height: 80px;
}

.forgot-pass {
  padding: 0 !important;
  float: right;
  color: #3b82f6;
}

.product-physical table .jsgrid-filter-row {
  display: none !important;
}
.product-physical table tr th:nth-child(2),
.product-physical table tr td:nth-child(2) {
  text-align: left;
}

.timer {
  padding-left: 10px;
  padding-right: 10px;
}
.timer span {
  text-align: center;
  position: relative;
}
.timer span .padding-l {
  padding-left: 0;
  position: absolute;
  left: 35px;
  top: 10px;
}

.product-buttons {
  padding: 0;
  text-align: right;
}

.qty-box1 .input-group .btnGtr1,
.qty-box1 .input-group .btnLess1 {
  padding: 12px;
  border: 1px solid #dddddd;
}
.qty-box1 .input-group .btnGtr1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.qty-box1 .input-group .btnLess1 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.qty-box1 input {
  padding: 0.48rem 0.75rem;
  border: 1px solid #dddddd;
  width: 80px;
  border-left: none;
  border-right: none;
}

.add-product img {
  width: 500px;
}
.add-product .imgUpload {
  width: 100px;
  margin-bottom: 10px;
}
.add-product ul li {
  display: flex;
  margin-bottom: 15px;
}
.add-product ul li .box-input-file {
  width: 50px;
  height: 50px;
  background-color: #f1f4fb;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.add-product ul li .box-input-file i {
  color: #3b82f6;
}
.add-product ul li .box-input-file .upload {
  position: absolute;
  width: 70px;
  left: 0;
  right: 0;
  opacity: 0;
}

.digital-add .col-form-label {
  font-family: Nunito;
}
.digital-add .form-control {
  font-size: 14px;
}
.digital-add .form-group .radio_animated {
  margin-right: 8px;
}
.digital-add textarea {
  width: 100%;
}

.add-product-form .form-group {
  align-items: center;
}
.add-product-form .form-group label {
  font-size: 16px;
  font-weight: 600;
}
.add-product-form .form-group:last-child {
  align-items: end;
}
.add-product-form .form-group .form-control {
  font-size: 14px;
}
.add-product-form .qty-box .input-group {
  justify-content: flex-start;
}
.add-product-form .qty-box .input-group button {
  padding: 12px;
}
.add-product-form .qty-box .input-group .btn-primary {
  background-color: transparent !important;
  border: 1px solid #ddd !important;
}
.add-product-form .qty-box .input-group .btn-primary.bootstrap-touchspin-down {
  border-right: none !important;
}
.add-product-form .qty-box .input-group .input-group-append {
  margin-left: 0;
}
.add-product-form .qty-box .input-group .input-group-append .btn {
  border-left: none !important;
}
.add-product-form #cke_editor1 .cke_inner .cke_contents.cke_reset {
  height: 100px !important;
}

.zoomContainer {
  top: 225px !important;
}

.chart-block canvas {
  width: 100%;
}

.sell-graph canvas {
  width: 100% !important;
  height: 300px !important;
}

.sales-carousel .value-graph h3 {
  color: #313131;
  font-weight: 600;
}
.sales-carousel .media .small-box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-chartjs canvas {
  width: 100% !important;
  height: 60px !important;
}

.order-graph h6 {
  color: #313131;
  font-family: Nunito;
  font-weight: 700;
  margin-bottom: 20px;
}
.order-graph .order-graph-bottom {
  margin-top: 30px;
}
.order-graph .order-graph-bottom h6 {
  color: #999;
  margin-left: 15px;
  margin-right: 30px;
}
.order-graph .order-graph-bottom h6 span {
  color: #313131;
}
.order-graph .order-graph-bottom .media {
  margin-bottom: 20px;
}
.order-graph .order-graph-bottom .media:last-child {
  margin-bottom: 0;
}
.order-graph .order-graph-bottom .media .order-shape-primary {
  background-color: #3b82f6;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-secondary {
  background-color: #86d3fc;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-success {
  background-color: #81ba00;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-danger {
  background-color: #e27272;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-info {
  background-color: #00a8ff;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-light {
  background-color: #f8f8f9;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-dark {
  background-color: #2a3142;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .media .order-shape-warning {
  background-color: #ffbc58;
  width: 18px;
  height: 8px;
  margin-top: 7px;
  border-radius: 4px;
}
.order-graph .order-graph-bottom .order-color-primary {
  background-color: #3b82f6;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-secondary {
  background-color: #86d3fc;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-success {
  background-color: #81ba00;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-danger {
  background-color: #e27272;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-info {
  background-color: #00a8ff;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-light {
  background-color: #f8f8f9;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-dark {
  background-color: #2a3142;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}
.order-graph .order-graph-bottom .order-color-warning {
  background-color: #ffbc58;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}

.datepickers-container {
  top: -86px;
}

.datepicker--nav {
  color: #3b82f6;
}

.datepicker--nav-action {
  color: #3b82f6;
  background-color: #3b82f6;
}

.datepicker--nav-action:hover {
  color: #3b82f6;
}

.datepicker--nav-title i {
  color: #3b82f6;
}

.table thead th {
  border-bottom: 2px solid #e8ebf2;
}

.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.gradient-line-1:after {
  bottom: -66px;
}
.page-wrapper .page-body-wrapper footer p {
  line-height: 1.7;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li {
  width: 100%;
}
.page-wrapper .page-body-wrapper .page-header .row h3 {
  color: #000;
}

.widget-cards {
  border-radius: 25px !important;
}

.static-top-widget div.align-self-center svg {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.static-top-widget .media-body {
  align-self: center !important;
}
.static-top-widget .media-body h3 {
  font-family: work-Sans, sans-serif;
  color: #ffffff;
}
.static-top-widget .media-body h3 small {
  font-size: 11px;
}
.static-top-widget .icons-widgets .text-center {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flot-chart-container {
  height: 400px;
}

.user-status table tbody tr td {
  vertical-align: middle;
}
.user-status table tbody tr td .d-inline-block {
  margin-top: 11px;
}
.user-status table tbody tr td .image-sm-size img {
  width: 41px;
}
.user-status table tbody tr:last-child td {
  padding-bottom: 0;
}
.user-status table thead tr th {
  border-top: 0;
  font-size: 16px;
  color: #2a3142;
  font-weight: 600;
  padding-top: 0;
}

.card-block .table-responsive .table caption {
  padding-left: 10px;
}
.card-block .table-responsive .table-bordered td {
  vertical-align: middle;
}
.card-block .table-border-radius {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.card-block .default-checkbox-align #checkbox1 {
  margin-right: 10px;
}
.card-block .default-checkbox-align #radio {
  margin-right: 5px;
}

.chart-vertical-center {
  display: flex;
  justify-content: center;
}

.products-admin .product-box {
  pdding: 20px;
}
.products-admin .product-box .color-variant {
  padding-top: 15px;
}
.products-admin .product-box .img-wrapper {
  position: relative;
  overflow: hidden;
  height: 170px;
}
.products-admin .product-box .img-wrapper .lable-block .lable3 {
  border-radius: 100%;
  background-color: #3b82f6;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 12px 6px;
  text-transform: uppercase;
  color: #ffffff;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.products-admin .product-box .img-wrapper .lable-block .lable4 {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  top: 7px;
  right: 7px;
  letter-spacing: 0.1em;
  z-index: 1;
}
.products-admin .product-box .product-detail .rating,
.products-admin .product-box .product-info .rating {
  margin-top: 15px;
}
.products-admin .product-box .product-detail .rating i,
.products-admin .product-box .product-info .rating i {
  padding-right: 5px;
  color: #ffa200;
}
.products-admin .product-box .product-detail .rating i:last-child,
.products-admin .product-box .product-info .rating i:last-child {
  color: #dddddd;
}
.products-admin .product-box .product-detail a:hover,
.products-admin .product-box .product-info a:hover {
  text-decoration: none;
}
.products-admin .product-box .product-detail h6,
.products-admin .product-box .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  transition: all 0.5s ease;
  font-size: 12px;
}
.products-admin .product-box .product-detail h4,
.products-admin .product-box .product-info h4 {
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  margin-bottom: 0;
  transition: all 0.5s ease;
}
@media screen and (min-width: 1700px) and (max-width: 2000px) {
  .products-admin .product-box .front {
    height: 260px !important;
  }
}
@media only screen and (min-width: 2001px) {
  .products-admin .product-box .front {
    height: 350px !important;
  }
}
.products-admin .product-box .front {
  position: relative;
  height: 170px;
}
.products-admin .product-box .front .product-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  border-radius: 100%;
  transform: scale(0);
  transition: all 0.3s ease;
}
.products-admin .product-box .front .product-hover ul li {
  display: inline-block;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  padding: 9px 14px;
  background-color: #ffffff;
  font-size: 18px;
  border-radius: 100%;
  line-height: 1.6;
  height: 45px;
  width: 45px;
  margin: 0 3px;
}
.products-admin .product-box .front .product-hover ul li .btn {
  padding: 0;
  background-color: transparent;
}
.products-admin .product-box .front .product-hover ul li .btn i {
  font-size: 16px;
}
.products-admin .product-box .front:hover .product-hover {
  opacity: 1;
  border-radius: 0%;
  transform: scale(1);
  transition: all 0.3s ease;
}
.products-admin .product-wrap .img-wrapper {
  position: relative;
  overflow: hidden;
}
.products-admin .product-wrap .img-wrapper .lable-block .lable3 {
  border-radius: 100%;
  background-color: #3b82f6;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 12px 6px;
  text-transform: uppercase;
  color: #ffffff;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.products-admin .product-wrap .img-wrapper .lable-block .lable4 {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  top: 7px;
  right: 7px;
  letter-spacing: 0.1em;
  z-index: 1;
}

.color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  transition: all 0.1s ease;
  vertical-align: middle;
}

.bg-light0 {
  background-color: #d0edff;
}

.bg-light1 {
  background-color: #f1e7e6;
}

.bg-light2 {
  background-color: #bfbfbf;
}

.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em;
}
.product-right h3 {
  font-size: 26px;
  color: #222222;
  margin-bottom: 15px;
}
.product-right .timer {
  margin-top: 10px;
  padding: 15px 10px;
  background-color: #f7f7f7;
  display: inline-block;
}
.product-right .timer p {
  font-size: 18px;
  color: #222222;
}
.product-right .timer span {
  width: 70px;
  display: inline-block;
}
.product-right .timer span .timer-cal {
  font-size: 12px;
  color: #777777;
}
.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-right .size-box ul li {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #f7f7f7;
  text-align: center;
}
.product-right .size-box ul li.active {
  background-color: #f7f7f7;
}
.product-right .size-box ul li a {
  color: #222222;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.product-right .color-variant li {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.product-right .product-title {
  color: #222222;
  text-transform: initial;
  font-weight: 700;
  margin-bottom: 0;
}

#carousel-container > .current-figure > #current-image,
#carousel-container > .current-figure > #current-image-legacy {
  margin-bottom: 30px;
}

.product-page-main .product-slider .owl-stage-outer {
  margin-top: 0;
}
.product-page-main .product-slider .owl-carousel .owl-stage-outer .owl-stage .owl-item.active.current {
  border: 1px solid #3b82f6;
}
.product-page-main .owl-stage-outer {
  margin-top: 30px;
}
.product-page-main .owl-stage-outer .owl-stage .owl-item.active.current {
  border: 1px solid #3b82f6 !important;
}

.br-theme-fontawesome-stars-o .br-widget a {
  color: #ffa800;
}
.br-theme-fontawesome-stars-o .br-widget a .br-selected,
.br-theme-fontawesome-stars-o .br-widget a .br-active:after {
  color: #ffa800;
  font: normal normal normal 14px/1 FontAwesome;
}

.fa {
  font-size: 16px;
  margin-right: 10px;
}

.fa-eye:before {
  color: #0f9aee;
}

.fa-edit:before {
  color: #37c936;
}

.fa-trash:before {
  color: #ff3c7e;
  cursor: pointer;
}

.no-mrg {
  margin: 0 !important;
}

.btn-danger {
  background-color: #ff3c7e;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link > .nav-link,
.nav-pills .show.active,
.nav-pills .show > .nav-link {
  background-color: #3b82f6;
}

.deactivate-account {
  margin-top: 30px;
}
.deactivate-account .btn {
  margin-top: 20px;
}

.account-setting h5 {
  margin-bottom: 20px;
}

.tab2-card ul {
  margin-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}
.tab2-card ul li a svg {
  width: 18px;
  vertical-align: middle;
}
.tab2-card .nav-tabs .nav-link {
  color: #333333;
}
.tab2-card .nav-tabs .nav-link.active, .tab2-card .nav-tabs .nav-link:focus, .tab2-card .nav-tabs .nav-link:hover {
  color: #3b82f6;
  border-color: transparent;
  border-bottom: 2px solid #3b82f6;
}
.tab2-card .nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
  border-bottom: 2px solid #3b82f6;
}

.profile-details img {
  margin-bottom: 20px;
}
.profile-details .social {
  margin-top: 15px;
}
.profile-details .social .btn-showcase {
  margin-bottom: -10px;
}
.profile-details .social .btn-showcase .btn {
  padding: 8px 13px;
  margin: 0 3px 20px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.profile-details .social .btn-showcase .btn-fb {
  background-color: #50598e;
  color: #ffffff;
}
.profile-details .social .btn-showcase .btn-google {
  background-color: #c64e40;
  color: #ffffff;
}
.profile-details .social .btn-showcase .btn-twitter {
  background-color: #6fa2d8;
  color: #ffffff;
}

.project-status {
  margin-top: 20px;
}
.project-status .media {
  margin-top: 20px;
}

.sm-progress-bar {
  height: 6px;
}

.profile-table table tbody tr td:first-child {
  min-width: 150px;
}
.profile-table table th,
.profile-table table td {
  border-top: none;
}

.report-employee .card-header {
  border-bottom: none !important;
}
.report-employee .flot-chart-container {
  height: 317px;
}

.sales-chart {
  height: 307px;
}
.sales-chart svg .ct-series-b .ct-point,
.sales-chart svg .ct-series-b .ct-line,
.sales-chart svg .ct-series-b .ct-bar,
.sales-chart svg .ct-series-b .ct-slice-donut {
  stroke: #e27272;
}

.expense-chart #area-chart1 {
  height: 307px;
}

.tab2-card .media {
  align-items: center;
}
.tab2-card .media img {
  margin-right: 20px;
}

footer.blockquote-footer {
  bottom: unset;
}

.bg-black {
  background-color: #000000;
}

.datepicker {
  box-shadow: 0 4px 14px rgba(59, 130, 246, 0.15);
}

.datepicker--day-name {
  color: #000000;
  font-weight: bold;
}

.datepicker--cell.-current- {
  color: #000000;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid #3b82f6;
}
.datepicker--cell.-focus- {
  background: #3b82f6;
  color: #ffffff;
}
.datepicker--cell.-selected- {
  background: #3b82f6;
}

.dropzone {
  margin-right: auto;
  margin-left: auto;
  padding: 50px;
  border: 2px dashed #3b82f6;
  border-radius: 15px;
  min-height: 150px;
}

.dropzone .dz-preview .dz-image {
  border-radius: 5px;
  border: none;
}

.dropzone i {
  font-size: 50px;
  color: #3b82f6;
  text-align: center;
}

.card .card-body {
  z-index: 0;
}

.tab-coupon {
  margin-bottom: 30px;
}

.needs-validation .permission-block .attribute-blocks {
  padding-left: 15px;
}
.needs-validation .permission-block .attribute-blocks .row {
  padding-left: 20px;
}
.needs-validation .permission-block .attribute-blocks + .attribute-blocks h5 {
  margin-top: 30px;
}
.needs-validation .permission-block .attribute-blocks h6 {
  border-bottom: 1px solid #eff0f1;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.needs-validation h4 {
  font-weight: 600;
  margin-bottom: 20px;
}
.needs-validation input {
  font-size: 14px;
}
.needs-validation input.error {
  border-color: #ff3c7e;
  background-color: #ffeff4;
}
.needs-validation input.valid {
  border-color: #37c936;
  background-color: #ebfaeb;
  color: #278d26;
}
.needs-validation .form-group {
  align-items: center;
}
.needs-validation .form-group label {
  margin-bottom: 0;
}
.needs-validation .form-group label span {
  color: red;
}
.needs-validation .form-group .checkbox {
  padding-left: 0;
}
.needs-validation .editor-label {
  align-items: end;
}
.needs-validation .editor-space {
  padding: 0;
}
.needs-validation .checkbox input {
  opacity: 0;
}
.needs-validation .checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.needs-validation .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -16px;
  border: 1px solid #e8ebf2;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.needs-validation .checkbox input[type=checkbox]:checked + label::before {
  font-family: IcoFont;
  content: "\efab";
  text-align: center;
  line-height: 1;
  font-size: 18px;
}
.needs-validation .checkbox-primary input[type=checkbox]:checked + label::before {
  border-color: #3b82f6;
  color: #3b82f6;
}
.needs-validation .radio-animated label {
  margin-right: 20px;
}
.needs-validation textarea {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 15px;
}

.transcation-datatable table th:nth-child(2),
.transcation-datatable table td:nth-child(2) {
  text-align: center !important;
}

.badge-warning {
  color: #ffffff;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin-top: 25px;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}
.dataTables_wrapper .dataTables_length label select {
  border-color: #eff0f1;
}
.dataTables_wrapper .dataTables_filter input[type=search] {
  border: 1px solid #eff0f1;
  padding: 0 15px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
}
.dataTables_wrapper .dataTables_paginate {
  margin-left: 15px !important;
  border: 1px solid #f6f7fb;
  border-radius: 0.25rem;
  padding-top: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #3b82f6;
  color: #ffffff !important;
  border: 1px solid #3b82f6;
  box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #3b82f6;
  color: #2a3142 !important;
  background: transparent !important;
}
.dataTables_wrapper table.dataTable {
  border: 1px solid #ebf1ff;
  width: 100%;
  overflow-x: auto;
}
.dataTables_wrapper table.dataTable.row-border tbody th,
.dataTables_wrapper table.dataTable.row-border tbody td, .dataTables_wrapper table.dataTable.display tbody th,
.dataTables_wrapper table.dataTable.display tbody td {
  border-top: 1px solid #ebf1ff !important;
}
.dataTables_wrapper table.dataTable tbody tr {
  background-color: rgba(241, 244, 251, 0.5);
}
.dataTables_wrapper table.dataTable tbody tr:hover {
  background-color: rgba(241, 244, 251, 0.7);
}
.dataTables_wrapper table.dataTable tbody tr .sorting_1 {
  background-color: rgba(241, 244, 251, 0.2) !important;
}
.dataTables_wrapper table.dataTable tbody tr.odd {
  background-color: #ffffff;
}
.dataTables_wrapper table.dataTable .vendor-list {
  align-items: center;
}
.dataTables_wrapper table.dataTable .vendor-list img {
  margin-right: 20px;
  height: 40px;
  width: auto !important;
}
.dataTables_wrapper table.dataTable td {
  padding: 0.75rem !important;
}
.dataTables_wrapper table.dataTable th {
  background-color: #f1f4fb !important;
  padding: 24px 0.75rem !important;
}
.dataTables_wrapper table.dataTable thead .sorting:before, .dataTables_wrapper table.dataTable thead .sorting:after,
.dataTables_wrapper table.dataTable thead .sorting_asc:before,
.dataTables_wrapper table.dataTable thead .sorting_asc:after,
.dataTables_wrapper table.dataTable thead .sorting_desc:before,
.dataTables_wrapper table.dataTable thead .sorting_desc:after,
.dataTables_wrapper table.dataTable thead .sorting_asc_disabled:before,
.dataTables_wrapper table.dataTable thead .sorting_asc_disabled:after,
.dataTables_wrapper table.dataTable thead .sorting_desc_disabled:before,
.dataTables_wrapper table.dataTable thead .sorting_desc_disabled:after {
  bottom: 25px;
}

.category-table table tr td,
.category-table table tr th {
  text-align: center;
}
.category-table table tr td:nth-child(2),
.category-table table tr th:nth-child(2) {
  text-align: left;
}

.order-table table tr td:nth-child(3),
.order-table table tr th:nth-child(3) {
  text-align: center;
}

.product-list table tr td,
.product-list table tr th,
.report-table table tr td,
.report-table table tr th {
  text-align: left !important;
}
.product-list table tr td:last-child,
.product-list table tr th:last-child,
.report-table table tr td:last-child,
.report-table table tr th:last-child {
  text-align: center !important;
}
.product-list table .jsgrid-filter-row,
.report-table table .jsgrid-filter-row {
  display: none !important;
}
.product-list table .jsgrid-cell,
.report-table table .jsgrid-cell {
  padding: 0.75rem;
}

.jsgrid-pager {
  padding: 2em 0 0;
}

.jsgrid-grid-header {
  border: 1px solid #e8efff;
}

.user-list img {
  border-radius: 100%;
}

/**=====================
  Admin CSS Ends
==========================**/
/**=====================
      Button CSS start
==========================**/
.btn {
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  transition: 0.3s ease-in-out;
}

.btn-lg {
  font-size: 18px;
}

.btn-sm {
  font-size: 12px;
}

.btn-xs {
  padding: 0.05rem 0.4rem;
  font-size: 11px;
}

.large-btn {
  margin-bottom: -20px;
}
.large-btn .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

.btn-air-primary {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-primary:hover, .btn-air-primary:active, .btn-air-primary:not([disabled]):not(.disabled):active {
  background-color: #0b63f3;
  border-color: #0b63f3;
}

.btn-air-secondary {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-secondary:hover, .btn-air-secondary:active, .btn-air-secondary:not([disabled]):not(.disabled):active {
  background-color: #54c1fb;
  border-color: #54c1fb;
}

.btn-air-success {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-success:hover, .btn-air-success:active, .btn-air-success:not([disabled]):not(.disabled):active {
  background-color: #5e8700;
  border-color: #5e8700;
}

.btn-air-danger {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-danger:hover, .btn-air-danger:active, .btn-air-danger:not([disabled]):not(.disabled):active {
  background-color: #d94848;
  border-color: #d94848;
}

.btn-air-info {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-info:hover, .btn-air-info:active, .btn-air-info:not([disabled]):not(.disabled):active {
  background-color: #0086cc;
  border-color: #0086cc;
}

.btn-air-light {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-light:hover, .btn-air-light:active, .btn-air-light:not([disabled]):not(.disabled):active {
  background-color: #dddde1;
  border-color: #dddde1;
}

.btn-air-dark {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-dark:hover, .btn-air-dark:active, .btn-air-dark:not([disabled]):not(.disabled):active {
  background-color: #161a23;
  border-color: #161a23;
}

.btn-air-warning {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-warning:hover, .btn-air-warning:active, .btn-air-warning:not([disabled]):not(.disabled):active {
  background-color: #ffa825;
  border-color: #ffa825;
}

.input-air-primary {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-primary:focus {
  border-color: #3b82f6;
}

.input-air-secondary {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-secondary:focus {
  border-color: #86d3fc;
}

.input-air-success {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-success:focus {
  border-color: #81ba00;
}

.input-air-danger {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-danger:focus {
  border-color: #e27272;
}

.input-air-info {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-info:focus {
  border-color: #00a8ff;
}

.input-air-light {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-light:focus {
  border-color: #f8f8f9;
}

.input-air-dark {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-dark:focus {
  border-color: #2a3142;
}

.input-air-warning {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}
.input-air-warning:focus {
  border-color: #ffbc58;
}

.form-bg-primary {
  background: #3b82f6;
  border-color: #3b82f6;
  color: #ffffff;
}
.form-bg-primary:focus {
  border-color: #3b82f6;
  background: #3b82f6;
  color: #ffffff;
}
.form-bg-primary:focus:active {
  border-color: #3b82f6;
  background: #3b82f6;
  color: #ffffff;
}

.form-bg-secondary {
  background: #86d3fc;
  border-color: #86d3fc;
  color: #ffffff;
}
.form-bg-secondary:focus {
  border-color: #86d3fc;
  background: #86d3fc;
  color: #ffffff;
}
.form-bg-secondary:focus:active {
  border-color: #86d3fc;
  background: #86d3fc;
  color: #ffffff;
}

.form-bg-success {
  background: #81ba00;
  border-color: #81ba00;
  color: #ffffff;
}
.form-bg-success:focus {
  border-color: #81ba00;
  background: #81ba00;
  color: #ffffff;
}
.form-bg-success:focus:active {
  border-color: #81ba00;
  background: #81ba00;
  color: #ffffff;
}

.form-bg-danger {
  background: #e27272;
  border-color: #e27272;
  color: #ffffff;
}
.form-bg-danger:focus {
  border-color: #e27272;
  background: #e27272;
  color: #ffffff;
}
.form-bg-danger:focus:active {
  border-color: #e27272;
  background: #e27272;
  color: #ffffff;
}

.form-bg-info {
  background: #00a8ff;
  border-color: #00a8ff;
  color: #ffffff;
}
.form-bg-info:focus {
  border-color: #00a8ff;
  background: #00a8ff;
  color: #ffffff;
}
.form-bg-info:focus:active {
  border-color: #00a8ff;
  background: #00a8ff;
  color: #ffffff;
}

.form-bg-light {
  background: #f8f8f9;
  border-color: #f8f8f9;
  color: #2a3142;
}
.form-bg-light:focus {
  border-color: #f8f8f9;
  background: #f8f8f9;
  color: #2a3142;
}
.form-bg-light:focus:active {
  border-color: #f8f8f9;
  background: #f8f8f9;
  color: #2a3142;
}

.form-bg-dark {
  background: #2a3142;
  border-color: #2a3142;
  color: #ffffff;
}
.form-bg-dark:focus {
  border-color: #2a3142;
  background: #2a3142;
  color: #ffffff;
}
.form-bg-dark:focus:active {
  border-color: #2a3142;
  background: #2a3142;
  color: #ffffff;
}

.form-bg-warning {
  background: #ffbc58;
  border-color: #ffbc58;
  color: #ffffff;
}
.form-bg-warning:focus {
  border-color: #ffbc58;
  background: #ffbc58;
  color: #ffffff;
}
.form-bg-warning:focus:active {
  border-color: #ffbc58;
  background: #ffbc58;
  color: #ffffff;
}

.btn-warning {
  color: #ffffff;
}
.btn-warning:hover, .btn-warning.disabled {
  color: #ffffff;
}

.btn-primary-gradien {
  background-image: linear-gradient(to right, #629bf8 0%, #1469f4 51%, #629bf8 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-primary-gradien:focus, .btn-primary-gradien:active, .btn-primary-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #629bf8, 0%, #1469f4, 100%, #ffffff) !important;
}

.btn-secondary-gradien {
  background-image: linear-gradient(to right, #aee1fd 0%, #5ec5fb 51%, #aee1fd 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-secondary-gradien:hover, .btn-secondary-gradien:focus, .btn-secondary-gradien:active, .btn-secondary-gradien.active, .btn-secondary-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #aee1fd, 0%, #5ec5fb, 100%, #ffffff) !important;
}

.btn-success-gradien {
  background-image: linear-gradient(to right, #9de300 0%, #659100 51%, #9de300 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-success-gradien:hover, .btn-success-gradien:focus, .btn-success-gradien:active, .btn-success-gradien.active, .btn-success-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #9de300, 0%, #659100, 100%, #ffffff) !important;
}

.btn-danger-gradien {
  background-image: linear-gradient(to right, #e99494 0%, #db5050 51%, #e99494 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-danger-gradien:hover, .btn-danger-gradien:focus, .btn-danger-gradien:active, .btn-danger-gradien.active, .btn-danger-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #e99494, 0%, #db5050, 100%, #ffffff) !important;
}

.btn-warning-gradien {
  background-image: linear-gradient(to right, #ffcc81 0%, #ffac2f 51%, #ffcc81 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-warning-gradien:hover, .btn-warning-gradien:focus, .btn-warning-gradien:active, .btn-warning-gradien.active, .btn-warning-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #ffcc81, 0%, #ffac2f, 100%, #ffffff) !important;
}

.btn-info-gradien {
  background-image: linear-gradient(to right, #29b6ff 0%, #008dd6 51%, #29b6ff 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-info-gradien:hover, .btn-info-gradien:focus, .btn-info-gradien:active, .btn-info-gradien.active, .btn-info-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #29b6ff, 0%, #008dd6, 100%, #ffffff) !important;
}

.btn-light-gradien {
  background-image: linear-gradient(to right, white 0%, #e2e2e6 51%, white 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-light-gradien:hover, .btn-light-gradien:focus, .btn-light-gradien:active, .btn-light-gradien.active, .btn-light-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, white, 0%, #e2e2e6, 100%, #ffffff) !important;
}

.btn-dark-gradien {
  background-image: linear-gradient(to right, #3a445b 0%, #1a1e29 51%, #3a445b 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-dark-gradien:hover, .btn-dark-gradien:focus, .btn-dark-gradien:active, .btn-dark-gradien.active, .btn-dark-gradien.hover {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #3a445b, 0%, #1a1e29, 100%, #ffffff) !important;
}

[class*=-gradien]:hover {
  background-size: 50% 100%;
  transition: all 0.3s ease;
  color: #ffffff;
}

/**=====================
 Button CSS end
==========================**/
/**=====================
      Card CSS Start
==========================**/
.card {
  margin-bottom: 30px;
  border: 0px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  align-items: center;
  justify-content: center;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: #3b82f6;
  font-size: 20px;
}
.card.full-card {
  position: fixed;
  top: 80px;
  z-index: 99999;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #eff0f1;
  width: calc(100vw - 272px);
  height: calc(100vh - 80px);
}
.card.full-card .card-body {
  overflow: auto;
}
.card .card-header {
  background-color: #ffffff;
  border-bottom: none;
  padding: 30px 20px;
  border-bottom: 1px solid #f8f8f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 20px;
  top: 25px;
  display: inline-block;
  float: right;
  padding: 7px 0;
  position: absolute;
}
.card .card-header .card-header-right .card-option {
  text-align: right;
  width: 35px;
  height: 20px;
  overflow: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.card .card-header .card-header-right .card-option li {
  display: inline-block;
}
.card .card-header .card-header-right i {
  margin: 0 5px;
  cursor: pointer;
  color: #2a3142;
  line-height: 20px;
}
.card .card-header .card-header-right i.icofont-refresh {
  font-size: 13px;
}
.card .card-header h5 {
  font-size: 18px;
  margin-bottom: 0;
  text-transform: initial;
  font-weight: 600;
  line-height: 24px;
}
.card .card-header > span {
  font-size: 12px;
  color: #777777;
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
}
.card .card-body {
  padding: 30px 20px;
  background-color: transparent;
}
.card .card-body p:last-child {
  margin-bottom: 0;
}
.card .sub-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
}
.card .card-footer {
  background-color: #ffffff;
  border-top: 1px solid #f8f8f9;
  padding: 30px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.card-header .nav-material {
  margin-bottom: -13px;
}

/**=====================
     Card CSS End
==========================**/
/**=====================
      Footer CSS Start
==========================**/
footer {
  background-color: #ffffff;
  border-top: 1px solid #f8f8f9;
  padding: 15px;
  bottom: 0;
  left: 0;
}
footer a {
  font-weight: 600;
}
footer.footer-starter-kit {
  bottom: -52px;
}

.blockquote-footer {
  margin-left: 0 !important;
  width: 885px !important;
}

.page-wrapper .page-body-wrapper footer {
  margin-left: 255px;
  transition: all 0.3s ease;
  bottom: 0;
  z-index: 8;
}
.page-wrapper .page-body-wrapper footer p {
  color: rgb(165, 165, 165);
}
.page-wrapper .page-body-wrapper footer p i {
  color: #e27272;
  margin-left: 5px;
}

/**=====================
      Footer CSS Ends
==========================**/
/**=====================
      General CSS Start
==========================**/
body {
  background-color: rgba(246, 246, 246, 0.6);
  font-size: 14px;
  overflow-x: hidden;
  font-family: work-Sans, sans-serif;
  color: #313131;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px;
}

* a {
  color: #3b82f6;
}

*.btn:focus {
  box-shadow: none !important;
}

p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

code {
  color: #3b82f6 !important;
  background-color: #f8f8f9;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px;
}

blockquote {
  border-left: 4px solid #e8ebf2;
  padding: 15px;
}

blockquote.text-center {
  border: none;
  padding: 15px;
}

blockquote.text-right {
  border-left: none;
  border-right: 4px solid #e8ebf2;
  padding: 15px;
}

:focus {
  outline-color: #3b82f6;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input:focus {
  outline-color: transparent;
}

.txt-dark {
  color: #1b252a !important;
}

.txt-success {
  color: #81ba00 !important;
}

.txt-danger {
  color: #e27272 !important;
}

/**=====================
      General CSS Ends
==========================**/
/**=====================
    Header CSS Start
==========================**/
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li.active > a, .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li:hover > a {
  color: #313131;
  border-left-color: #2a3142;
  transition: 0.3s;
}

.onhover-show-div {
  box-shadow: 0 0 2px 2px #f8f8f9;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.nav-menus .onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}
.nav-menus .onhover-dropdown:hover .onhover-show-div:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 2;
}
.nav-menus .onhover-dropdown:hover .onhover-show-div:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #898989;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

/*======= Page Header css Start ======= */
.page-wrapper .page-main-header {
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  width: calc(100% - 255px);
  display: flex;
  align-items: center;
  margin-left: 255px;
  transition: 0.3s;
}
.page-wrapper .page-main-header.open {
  margin-left: 0;
  width: 100%;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  padding: 0 15px;
  position: relative;
  background-color: #f6f6f7;
}
.page-wrapper .page-body-wrapper .page-header {
  padding-top: 30px;
  padding-bottom: 30px;
}
.page-wrapper .page-body-wrapper .page-header .row {
  align-items: center;
}
.page-wrapper .page-body-wrapper .page-header .row .page-header-left {
  display: flex;
  align-items: center;
}
.page-wrapper .page-body-wrapper .page-header .row h3 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 800;
  text-transform: uppercase;
  font-family: Nunito;
}
.page-wrapper .page-body-wrapper .page-header .row h3 small {
  display: block;
  font-size: 12px;
  margin-top: 7px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #777777;
}
.page-wrapper .page-body-wrapper .page-header .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}
.page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item {
  font-family: Nunito;
}
.page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "/";
}
.page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item a svg {
  width: 14px;
  height: 14px;
  vertical-align: text-top;
}

/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.offcanvas .page-wrapper .page-body-wrapper .page-body {
  position: relative;
}
.offcanvas .page-wrapper .page-body-wrapper .page-body:before {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 10;
  right: 0;
  left: 255px;
}

.page-main-header {
  max-width: 100vw;
}
.page-main-header .main-header-right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin: 0;
}
.page-main-header .main-header-right svg {
  color: #3b82f6;
}
.page-main-header .main-header-right svg line,
.page-main-header .main-header-right svg polyline {
  color: #3b82f6;
}
.page-main-header .main-header-right .nav-left i {
  margin-right: 20px;
}
.page-main-header .main-header-right .nav-left input:focus {
  outline: 0 !important;
}
.page-main-header .main-header-right .nav-right {
  text-align: right;
  padding-left: 0px;
}
.page-main-header .main-header-right .nav-right ul li .media .dotted-animation {
  position: relative;
  right: -3px;
  top: -7px;
}
.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid #3b82f6;
  border-radius: 70px;
  animation: heartbit 1s ease-out;
  animation-iteration-count: infinite;
}
.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
  width: 6px;
  height: 6px;
  border-radius: 30px;
  background-color: #3b82f6;
  position: absolute;
  right: 6px;
  top: -10px;
}
.page-main-header .main-header-right .nav-right ul li svg {
  margin-top: 10px;
  width: 18px;
  height: 18px;
}
.page-main-header .main-header-right .nav-right ul li svg path {
  color: #3b82f6;
}
.page-main-header .main-header-right .nav-right ul li .dot {
  width: 3px;
  height: 3px;
  border-radius: 30px;
  background-color: #3b82f6;
  position: absolute;
  right: 17px;
  bottom: 6px;
  animation: blink 1.5s infinite;
}
.page-main-header .main-header-right .nav-right ul li .dot-chat {
  right: 4px;
  bottom: -11px;
}
.page-main-header .main-header-right .nav-right .notification {
  position: absolute;
  top: 21px;
  right: -1px;
  font-size: 9px;
  animation: blink 1.5s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.page-main-header .main-header-right .nav-right .icon-user {
  font-size: 16px;
}
@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.page-main-header .main-header-right .nav-right > ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.page-main-header .main-header-right .nav-right > ul > li {
  position: relative;
  border-left: 1px solid #eff0f1;
  padding: 0 20px;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child {
  width: 25%;
  border-left: none;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group {
  width: 100%;
  position: relative;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:focus {
  outline-color: transparent;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group input:focus {
  outline-color: transparent;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 51px;
  top: 9px;
}
.page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  top: 11px;
  left: 22px;
  color: #8e8e8e;
}
.page-main-header .main-header-right .nav-right > ul > li:last-child {
  border-left: none;
  padding-right: 0;
}
.page-main-header .main-header-right .nav-right > ul > li:nth-child(5) {
  border-left: none;
  border-right: 1px solid #eff0f1;
  padding-left: 0;
}
.page-main-header .main-header-right .nav-right > ul > li h6 {
  margin-top: 4px;
  margin-bottom: 4px;
  color: #3b82f6;
}
.page-main-header .main-header-right .nav-right > ul > li h6 ul {
  left: inherit;
  right: -10px;
  width: 130px;
}
.page-main-header .main-header-right .nav-right > ul > li h6 ul:before, .page-main-header .main-header-right .nav-right > ul > li h6 ul:after {
  left: inherit;
  right: 10px;
}
.page-main-header .main-header-right .nav-right > ul > li h6 ul li {
  display: block;
}
.page-main-header .main-header-right .nav-right > ul > li h6 ul li a {
  font-size: 14px;
  color: #2a3142;
}
.page-main-header .main-header-right .nav-right > ul > li h6 ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2a3142;
}
.page-main-header .main-header-right .nav-right > ul .flag-icon {
  font-size: 16px;
}
.page-main-header .main-header-right .nav-right .notification-dropdown {
  top: 57px;
}
.page-main-header .main-header-right .nav-right .notification-dropdown .txt-success svg polyline {
  color: #81ba00;
}
.page-main-header .main-header-right .nav-right .notification-dropdown .txt-danger svg circle {
  color: #e27272;
}
.page-main-header .main-header-right .nav-right .language-dropdown {
  width: 160px;
  text-align: left;
  top: 57px;
}
.page-main-header .main-header-right .nav-right .language-dropdown li {
  padding-top: 10px;
}
.page-main-header .main-header-right .nav-right .language-dropdown li a {
  color: #2a3142;
}
.page-main-header .main-header-right .nav-right .language-dropdown li a i {
  margin-right: 10px;
}
.page-main-header .main-header-right .nav-right .language-dropdown li:first-child {
  padding-top: 0;
}
.page-main-header .main-header-right .nav-right .profile-dropdown {
  right: -10px;
  left: inherit;
  width: 180px;
  top: 30px;
}
.page-main-header .main-header-right .nav-right .profile-dropdown:before, .page-main-header .main-header-right .nav-right .profile-dropdown:after {
  left: inherit;
  right: 10px;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li {
  display: block;
  text-align: left;
  padding-top: 10px;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(3) {
  padding-bottom: 10px;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(4) {
  border-top: 1px solid #eff0f1;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li:first-child {
  padding-top: 0;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
  color: #3b82f6;
  transition: 0.3s;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg {
  color: #3b82f6 !important;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg polyline {
  color: #3b82f6 !important;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li a {
  color: #313131;
  transition: 0.3s;
  display: flex;
  align-items: center;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
  margin-right: 10px;
  color: #313131;
}
.page-main-header .main-header-right .nav-right .profile-dropdown li a svg polyline {
  color: #313131;
}
.page-main-header .main-header-right li {
  display: inline-block;
  position: relative;
}

.nav-menus .notification-badge {
  position: absolute;
  right: 10px;
  top: 1px;
  padding: 4px 7px;
}
.nav-menus .onhover-dropdown {
  cursor: pointer;
  position: relative;
}
.nav-menus .onhover-dropdown:before {
  display: none;
}
ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: -18px;
  left: initial;
}
ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
  left: inherit !important;
  right: 35px !important;
}
ul.notification-dropdown.onhover-show-div li {
  display: block;
  padding: 12px 20px;
  border-bottom: 1px solid #f8f8f9;
  text-align: left;
}
ul.notification-dropdown.onhover-show-div li h6 small {
  padding-top: 5px;
  color: #898989;
  font-size: 12px;
}
ul.notification-dropdown.onhover-show-div li span svg {
  margin-top: 0 !important;
  margin-right: 10px;
  vertical-align: text-top;
}
ul.notification-dropdown.onhover-show-div li span .shopping-color path,
ul.notification-dropdown.onhover-show-div li span .shopping-color line {
  color: #3b82f6;
}
ul.notification-dropdown.onhover-show-div li span .download-color path,
ul.notification-dropdown.onhover-show-div li span .download-color line {
  color: #81ba00;
}
ul.notification-dropdown.onhover-show-div li span .alert-color path,
ul.notification-dropdown.onhover-show-div li span .alert-color line {
  color: #e27272;
}
ul.notification-dropdown.onhover-show-div li p {
  margin-left: 30px;
}
ul.notification-dropdown.onhover-show-div li + li:hover {
  background-color: #f8f8f9;
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: #ffffff;
  transition: all linear 0.3s;
}
.onhover-show-div li a svg {
  margin-top: 0 !important;
}
.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #313131 !important;
}

/**======Main Header css Ends ======**/
.nav-menus .search-form input {
  border: 1px solid #eff0f1;
  padding: 10px 10px 10px 70px;
  border-radius: 50px;
  background-color: #f8f8f9;
}

/**=====================
    Sidebar CSS Start
==========================**/
.page-wrapper .page-body-wrapper .sidebar {
  height: calc(100vh - 80px);
  overflow: auto;
  box-shadow: 0 0 11px rgba(143, 164, 232, 0.08);
}
.page-wrapper .page-body-wrapper .page-sidebar {
  width: 255px;
  position: fixed;
  background: #ffffff;
  top: 0;
  height: 100vh;
  z-index: 9;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
  display: inline-flex;
  width: 100%;
  height: 80px;
  padding: 12px;
  align-items: center;
  background-color: transparent;
  z-index: 10;
}
.page-wrapper .page-body-wrapper .page-sidebar .main-header-left .logo-wrapper {
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user {
  padding: 25px 10px;
  box-shadow: 3px 2px 7px -1px rgba(127, 151, 249, 0.13);
  position: relative;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6 {
  color: #3b82f6;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 3px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user img {
  box-shadow: 0 0 15px rgba(68, 102, 242, 0.3);
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 20px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-bottom: 12px;
  padding-top: 12px;
  text-transform: capitalize;
  font-weight: 600;
  color: #313131;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
  width: 14px;
  height: 14px;
  margin-right: 14px;
  stroke-width: 3px;
  vertical-align: text-bottom;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu.menu-open li.active .fa-angle-down:before {
  content: "\f104";
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
  display: block;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  color: #3b82f6;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  color: #3b82f6;
  padding-left: 3px;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i {
  margin-right: 7px;
  text-align: right;
  margin-top: 3px;
  font-size: 15px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i:before {
  content: "\f105";
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i ~ i {
  margin-right: 0;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li .label {
  margin-top: 3px;
  margin-right: 5px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li .badge {
  margin-left: 50px;
  text-transform: capitalize;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.sidebar-header {
  margin-bottom: 0;
  padding: 15px;
  color: #313131;
  font-weight: 600;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li > a > .fa-angle-down {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  margin-top: 10px;
  transform: rotate(-90deg);
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > a > .fa-angle-right:before {
  content: "\f107";
  font-family: FontAwesome;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
  display: block;
  margin-left: 15px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu a i.pull-right {
  margin-top: 10px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu a {
  text-decoration: none;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu {
  display: none;
  animation: 0.3s alternate sidebar-menu-open forwards;
  list-style: none;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu .sidebar-submenu {
  padding-top: 0 !important;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a {
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 14px;
  color: #898989;
  transition: 0.3s;
  text-transform: initial;
  position: relative;
  padding-left: 10px;
  line-height: 2.5;
  letter-spacing: 0.7px;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-circle {
  width: 12px;
  font-size: 4px;
  position: absolute;
  left: 0;
  top: 13px;
  display: none;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > i {
  width: auto;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-angle-down {
  width: auto;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a:hover {
  color: #3b82f6;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a.active {
  color: #3b82f6;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li.active > a {
  color: #313131;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li.active > a.active {
  color: #3b82f6;
}
.page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
  margin-left: 255px;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar.open {
  display: block;
  margin-left: calc(-260px);
}
.page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body {
  margin-left: 0;
  transition: 0.3s;
}
.page-wrapper .page-body-wrapper .page-sidebar.open ~ footer {
  margin-left: 0;
  padding-right: 15px;
}
.page-wrapper .page-body-wrapper .page-sidebar.open ~ .footer-fix {
  width: calc(100% - 0px);
}
.page-wrapper .page-body-wrapper .sidebar-close .page-sidebar {
  transition: 0.3s;
  transform: translate(-255px);
}
.page-wrapper .page-body-wrapper .sidebar-close .page-body {
  transition: 0.3s;
  margin-left: 0 !important;
}

.right-sidebar {
  top: 81px;
  right: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  z-index: 9;
  background-color: #fff;
  transition: 0.5s;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}
.right-sidebar.show {
  right: 0;
  transition: 0.3s;
}
.right-sidebar .modal-header .modal-title {
  padding-top: 2px;
}
.right-sidebar .friend-list-search {
  position: relative;
  background-color: #f8f8f9;
  padding: 20px;
}
.right-sidebar .friend-list-search input {
  color: #898989;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #f8f8f9;
  padding: 10px 15px;
  border-radius: 25px;
  letter-spacing: 1px;
}
.right-sidebar .friend-list-search .fa {
  position: absolute;
  right: 35px;
  top: 34px;
  font-size: 14px;
  color: #e8ebf2;
}
.right-sidebar .chat-box .people-list ul {
  padding-top: 20px;
}
.right-sidebar .chat-box .people-list ul li {
  position: relative;
}
.right-sidebar svg {
  width: 16px;
  height: 16px;
  margin-top: 5px;
}

/**=====================
    Sidebar CSS Ends
==========================**/
/**=====================
      Generic CSS Start
==========================**/
/*====== Padding css starts ======*/
.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/
/*====== Border-radius css starts ======*/
.b-r-0 {
  border-radius: 0px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

.f-82 {
  font-size: 82px;
}

.f-84 {
  font-size: 84px;
}

.f-86 {
  font-size: 86px;
}

.f-88 {
  font-size: 88px;
}

.f-90 {
  font-size: 90px;
}

.f-92 {
  font-size: 92px;
}

.f-94 {
  font-size: 94px;
}

.f-96 {
  font-size: 96px;
}

.f-98 {
  font-size: 98px;
}

.f-100 {
  font-size: 100px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  text-decoration: dashed;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  text-decoration: solid;
}

.text-wavy {
  text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  text-decoration: double;
}

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.p-static {
  position: static;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.p-initial {
  position: initial;
}

.p-inherit {
  position: inherit;
}

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-10 {
  width: 10px !important;
}

.img-20 {
  width: 20px !important;
}

.img-30 {
  width: 30px !important;
}

.img-40 {
  width: 40px !important;
}

.img-50 {
  width: 50px !important;
}

.img-60 {
  width: 60px !important;
}

.img-70 {
  width: 70px !important;
}

.img-80 {
  width: 80px !important;
}

.img-90 {
  width: 90px !important;
}

.img-100 {
  width: 100px !important;
}

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.font-primary {
  color: #3b82f6 !important;
}

.font-secondary {
  color: #86d3fc !important;
}

.font-success {
  color: #81ba00 !important;
}

.font-danger {
  color: #e27272 !important;
}

.font-info {
  color: #00a8ff !important;
}

.font-light {
  color: #f8f8f9 !important;
}

.font-dark {
  color: #2a3142 !important;
}

.font-warning {
  color: #ffbc58 !important;
}

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-theme {
  background-color: #3b82f6;
}

.label-primary {
  background-color: #3b82f6;
}

.label-secondary {
  background-color: #86d3fc;
}

.label-success {
  background-color: #81ba00;
}

.label-danger {
  background-color: #e27272;
}

.label-info {
  background-color: #00a8ff;
}

.label-light {
  background-color: #f8f8f9;
}

.label-dark {
  background-color: #2a3142;
}

.label-warning {
  background-color: #ffbc58;
}

/*======= Label-color css ends  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background-color: #3b82f6;
}

.badge-secondary {
  background-color: #86d3fc;
}

.badge-success {
  background-color: #81ba00;
}

.badge-danger {
  background-color: #e27272;
}

.badge-info {
  background-color: #00a8ff;
}

.badge-light {
  background-color: #f8f8f9;
}

.badge-dark {
  background-color: #2a3142;
}

.badge-warning {
  background-color: #ffbc58;
}

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-primary {
  background-color: #3b82f6 !important;
  color: #ffffff;
}

.bg-secondary {
  background-color: #86d3fc !important;
  color: #ffffff;
}

.bg-success {
  background-color: #81ba00 !important;
  color: #ffffff;
}

.bg-danger {
  background-color: #e27272 !important;
  color: #ffffff;
}

.bg-info {
  background-color: #00a8ff !important;
  color: #ffffff;
}

.bg-light {
  background-color: #f8f8f9 !important;
  color: #ffffff;
}

.bg-dark {
  background-color: #2a3142 !important;
  color: #ffffff;
}

.bg-warning {
  background-color: #ffbc58 !important;
  color: #ffffff;
}

/*======= Background-color css end  ======= */
/*======= Font-color css starts  ======= */
/*======= Font-color css end  ======= */
/*======= Button-color css starts  ======= */
.btn-primary {
  background-color: #3b82f6 !important;
  border-color: #3b82f6 !important;
}
.btn-primary.disabled {
  background-color: #3b82f6 !important;
  border-color: #3b82f6 !important;
}
.btn-primary:disabled {
  background-color: #3b82f6 !important;
  border-color: #3b82f6 !important;
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #cddffd;
}

.btn-secondary {
  background-color: #86d3fc !important;
  border-color: #86d3fc !important;
}
.btn-secondary.disabled {
  background-color: #86d3fc !important;
  border-color: #86d3fc !important;
}
.btn-secondary:disabled {
  background-color: #86d3fc !important;
  border-color: #86d3fc !important;
}
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem white;
}

.btn-success {
  background-color: #81ba00 !important;
  border-color: #81ba00 !important;
}
.btn-success.disabled {
  background-color: #81ba00 !important;
  border-color: #81ba00 !important;
}
.btn-success:disabled {
  background-color: #81ba00 !important;
  border-color: #81ba00 !important;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem #cbff54;
}

.btn-danger {
  background-color: #e27272 !important;
  border-color: #e27272 !important;
}
.btn-danger.disabled {
  background-color: #e27272 !important;
  border-color: #e27272 !important;
}
.btn-danger:disabled {
  background-color: #e27272 !important;
  border-color: #e27272 !important;
}
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem #fcf1f1;
}

.btn-info {
  background-color: #00a8ff !important;
  border-color: #00a8ff !important;
}
.btn-info.disabled {
  background-color: #00a8ff !important;
  border-color: #00a8ff !important;
}
.btn-info:disabled {
  background-color: #00a8ff !important;
  border-color: #00a8ff !important;
}
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem #99dcff;
}

.btn-light {
  background-color: #f8f8f9 !important;
  border-color: #f8f8f9 !important;
}
.btn-light.disabled {
  background-color: #f8f8f9 !important;
  border-color: #f8f8f9 !important;
}
.btn-light:disabled {
  background-color: #f8f8f9 !important;
  border-color: #f8f8f9 !important;
}
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem white;
}

.btn-dark {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important;
}
.btn-dark.disabled {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important;
}
.btn-dark:disabled {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important;
}
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem #67779e;
}

.btn-warning {
  background-color: #ffbc58 !important;
  border-color: #ffbc58 !important;
}
.btn-warning.disabled {
  background-color: #ffbc58 !important;
  border-color: #ffbc58 !important;
}
.btn-warning:disabled {
  background-color: #ffbc58 !important;
  border-color: #ffbc58 !important;
}
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem #fff9f1;
}

/*======= Button-color css ends  ======= */
.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #3b82f6;
  color: #3b82f6;
  background-color: transparent;
}
.btn-outline-primary-2x:hover, .btn-outline-primary-2x:focus, .btn-outline-primary-2x:active, .btn-outline-primary-2x.active {
  color: white;
  background-color: #0b63f3 !important;
  border-color: #0b63f3 !important;
  box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #86d3fc;
  color: #86d3fc;
  background-color: transparent;
}
.btn-outline-secondary-2x:hover, .btn-outline-secondary-2x:focus, .btn-outline-secondary-2x:active, .btn-outline-secondary-2x.active {
  color: white;
  background-color: #54c1fb !important;
  border-color: #54c1fb !important;
  box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #81ba00;
  color: #81ba00;
  background-color: transparent;
}
.btn-outline-success-2x:hover, .btn-outline-success-2x:focus, .btn-outline-success-2x:active, .btn-outline-success-2x.active {
  color: white;
  background-color: #5e8700 !important;
  border-color: #5e8700 !important;
  box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #e27272;
  color: #e27272;
  background-color: transparent;
}
.btn-outline-danger-2x:hover, .btn-outline-danger-2x:focus, .btn-outline-danger-2x:active, .btn-outline-danger-2x.active {
  color: white;
  background-color: #d94848 !important;
  border-color: #d94848 !important;
  box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #00a8ff;
  color: #00a8ff;
  background-color: transparent;
}
.btn-outline-info-2x:hover, .btn-outline-info-2x:focus, .btn-outline-info-2x:active, .btn-outline-info-2x.active {
  color: white;
  background-color: #0086cc !important;
  border-color: #0086cc !important;
  box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #f8f8f9;
  color: #f8f8f9;
  background-color: transparent;
}
.btn-outline-light-2x:hover, .btn-outline-light-2x:focus, .btn-outline-light-2x:active, .btn-outline-light-2x.active {
  color: white;
  background-color: #dddde1 !important;
  border-color: #dddde1 !important;
  box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2a3142;
  color: #2a3142;
  background-color: transparent;
}
.btn-outline-dark-2x:hover, .btn-outline-dark-2x:focus, .btn-outline-dark-2x:active, .btn-outline-dark-2x.active {
  color: white;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
  box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #ffbc58;
  color: #ffbc58;
  background-color: transparent;
}
.btn-outline-warning-2x:hover, .btn-outline-warning-2x:focus, .btn-outline-warning-2x:active, .btn-outline-warning-2x.active {
  color: white;
  background-color: #ffa825 !important;
  border-color: #ffa825 !important;
  box-shadow: none;
}

.btn-outline-primary {
  border-color: #3b82f6;
  color: #3b82f6;
  background-color: transparent;
}
.btn-outline-primary.disabled {
  color: #3b82f6;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
  color: white;
  background-color: #0b63f3 !important;
  border-color: #0b63f3 !important;
}

.btn-outline-secondary {
  border-color: #86d3fc;
  color: #86d3fc;
  background-color: transparent;
}
.btn-outline-secondary.disabled {
  color: #86d3fc;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
  color: white;
  background-color: #54c1fb !important;
  border-color: #54c1fb !important;
}

.btn-outline-success {
  border-color: #81ba00;
  color: #81ba00;
  background-color: transparent;
}
.btn-outline-success.disabled {
  color: #81ba00;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active {
  color: white;
  background-color: #5e8700 !important;
  border-color: #5e8700 !important;
}

.btn-outline-danger {
  border-color: #e27272;
  color: #e27272;
  background-color: transparent;
}
.btn-outline-danger.disabled {
  color: #e27272;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active {
  color: white;
  background-color: #d94848 !important;
  border-color: #d94848 !important;
}

.btn-outline-info {
  border-color: #00a8ff;
  color: #00a8ff;
  background-color: transparent;
}
.btn-outline-info.disabled {
  color: #00a8ff;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active {
  color: white;
  background-color: #0086cc !important;
  border-color: #0086cc !important;
}

.btn-outline-light {
  border-color: #f8f8f9;
  color: #f8f8f9;
  background-color: transparent;
  color: #2a3142;
}
.btn-outline-light.disabled {
  color: #f8f8f9;
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active {
  color: white;
  background-color: #dddde1 !important;
  border-color: #dddde1 !important;
}

.btn-outline-dark {
  border-color: #2a3142;
  color: #2a3142;
  background-color: transparent;
}
.btn-outline-dark.disabled {
  color: #2a3142;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active {
  color: white;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-outline-warning {
  border-color: #ffbc58;
  color: #ffbc58;
  background-color: transparent;
}
.btn-outline-warning.disabled {
  color: #ffbc58;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active {
  color: white;
  background-color: #ffa825 !important;
  border-color: #ffa825 !important;
}

.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #0b63f3;
  border-color: #0b63f3;
  box-shadow: none !important;
}
.btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active:active, .btn-primary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0b63f3;
  border-color: #0b63f3;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #54c1fb;
  border-color: #54c1fb;
  box-shadow: none !important;
}
.btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active:active, .btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #54c1fb;
  border-color: #54c1fb;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #5e8700;
  border-color: #5e8700;
  box-shadow: none !important;
}
.btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active:active, .btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #5e8700;
  border-color: #5e8700;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #d94848;
  border-color: #d94848;
  box-shadow: none !important;
}
.btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active:active, .btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #d94848;
  border-color: #d94848;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #0086cc;
  border-color: #0086cc;
  box-shadow: none !important;
}
.btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active:active, .btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0086cc;
  border-color: #0086cc;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #dddde1;
  border-color: #dddde1;
  box-shadow: none !important;
}
.btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active:active, .btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #dddde1;
  border-color: #dddde1;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #161a23;
  border-color: #161a23;
  box-shadow: none !important;
}
.btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active:active, .btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #161a23;
  border-color: #161a23;
}

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #ffa825;
  border-color: #ffa825;
  box-shadow: none !important;
}
.btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active:active, .btn-warning:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #ffa825;
  border-color: #ffa825;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #3b82f6;
  border-color: #3b82f6;
  box-shadow: none !important;
  color: white;
}
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #0b63f3 !important;
  border-color: #0b63f3 !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #86d3fc;
  border-color: #86d3fc;
  box-shadow: none !important;
  color: white;
}
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #54c1fb !important;
  border-color: #54c1fb !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #81ba00;
  border-color: #81ba00;
  box-shadow: none !important;
  color: white;
}
.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-success-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-success-2x:not([disabled]):not(.disabled).active:active, .btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #5e8700 !important;
  border-color: #5e8700 !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #e27272;
  border-color: #e27272;
  box-shadow: none !important;
  color: white;
}
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:active, .btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #d94848 !important;
  border-color: #d94848 !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #00a8ff;
  border-color: #00a8ff;
  box-shadow: none !important;
  color: white;
}
.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-info-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-info-2x:not([disabled]):not(.disabled).active:active, .btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #0086cc !important;
  border-color: #0086cc !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #f8f8f9;
  border-color: #f8f8f9;
  box-shadow: none !important;
  color: white;
}
.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-light-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-light-2x:not([disabled]):not(.disabled).active:active, .btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #dddde1 !important;
  border-color: #dddde1 !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2a3142;
  border-color: #2a3142;
  box-shadow: none !important;
  color: white;
}
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:active, .btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #ffbc58;
  border-color: #ffbc58;
  box-shadow: none !important;
  color: white;
}
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:active, .btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #ffa825 !important;
  border-color: #ffa825 !important;
}

/*======= Table-Border-Bottom-color css starts  ======= */
table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #3b82f6;
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #86d3fc;
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #81ba00;
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #e27272;
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #00a8ff;
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #f8f8f9;
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2a3142;
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #ffbc58;
}

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #3b82f6;
  color: #ffffff;
  border: 3px solid #3b82f6;
}
.table-styling .table-primary thead,
.table-styling.table-primary thead {
  background-color: #0b63f3;
  border: 3px solid #0b63f3;
}

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #86d3fc;
  color: #ffffff;
  border: 3px solid #86d3fc;
}
.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
  background-color: #54c1fb;
  border: 3px solid #54c1fb;
}

.table-styling .table-success,
.table-styling.table-success {
  background-color: #81ba00;
  color: #ffffff;
  border: 3px solid #81ba00;
}
.table-styling .table-success thead,
.table-styling.table-success thead {
  background-color: #5e8700;
  border: 3px solid #5e8700;
}

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #e27272;
  color: #ffffff;
  border: 3px solid #e27272;
}
.table-styling .table-danger thead,
.table-styling.table-danger thead {
  background-color: #d94848;
  border: 3px solid #d94848;
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #00a8ff;
  color: #ffffff;
  border: 3px solid #00a8ff;
}
.table-styling .table-info thead,
.table-styling.table-info thead {
  background-color: #0086cc;
  border: 3px solid #0086cc;
}

.table-styling .table-light,
.table-styling.table-light {
  background-color: #f8f8f9;
  color: #ffffff;
  border: 3px solid #f8f8f9;
}
.table-styling .table-light thead,
.table-styling.table-light thead {
  background-color: #dddde1;
  border: 3px solid #dddde1;
}

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2a3142;
  color: #ffffff;
  border: 3px solid #2a3142;
}
.table-styling .table-dark thead,
.table-styling.table-dark thead {
  background-color: #161a23;
  border: 3px solid #161a23;
}

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #ffbc58;
  color: #ffffff;
  border: 3px solid #ffbc58;
}
.table-styling .table-warning thead,
.table-styling.table-warning thead {
  background-color: #ffa825;
  border: 3px solid #ffa825;
}

/*======= Table styling css ends  ======= */
/*======= All-Borders-color css starts  ======= */
.b-primary {
  border: 1px solid #3b82f6 !important;
}

.b-t-primary {
  border-top: 1px solid #3b82f6 !important;
}

.b-b-primary {
  border-bottom: 1px solid #3b82f6 !important;
}

.b-l-primary {
  border-left: 1px solid #3b82f6 !important;
}

.b-r-primary {
  border-right: 1px solid #3b82f6 !important;
}

.b-secondary {
  border: 1px solid #86d3fc !important;
}

.b-t-secondary {
  border-top: 1px solid #86d3fc !important;
}

.b-b-secondary {
  border-bottom: 1px solid #86d3fc !important;
}

.b-l-secondary {
  border-left: 1px solid #86d3fc !important;
}

.b-r-secondary {
  border-right: 1px solid #86d3fc !important;
}

.b-success {
  border: 1px solid #81ba00 !important;
}

.b-t-success {
  border-top: 1px solid #81ba00 !important;
}

.b-b-success {
  border-bottom: 1px solid #81ba00 !important;
}

.b-l-success {
  border-left: 1px solid #81ba00 !important;
}

.b-r-success {
  border-right: 1px solid #81ba00 !important;
}

.b-danger {
  border: 1px solid #e27272 !important;
}

.b-t-danger {
  border-top: 1px solid #e27272 !important;
}

.b-b-danger {
  border-bottom: 1px solid #e27272 !important;
}

.b-l-danger {
  border-left: 1px solid #e27272 !important;
}

.b-r-danger {
  border-right: 1px solid #e27272 !important;
}

.b-info {
  border: 1px solid #00a8ff !important;
}

.b-t-info {
  border-top: 1px solid #00a8ff !important;
}

.b-b-info {
  border-bottom: 1px solid #00a8ff !important;
}

.b-l-info {
  border-left: 1px solid #00a8ff !important;
}

.b-r-info {
  border-right: 1px solid #00a8ff !important;
}

.b-light {
  border: 1px solid #f8f8f9 !important;
}

.b-t-light {
  border-top: 1px solid #f8f8f9 !important;
}

.b-b-light {
  border-bottom: 1px solid #f8f8f9 !important;
}

.b-l-light {
  border-left: 1px solid #f8f8f9 !important;
}

.b-r-light {
  border-right: 1px solid #f8f8f9 !important;
}

.b-dark {
  border: 1px solid #2a3142 !important;
}

.b-t-dark {
  border-top: 1px solid #2a3142 !important;
}

.b-b-dark {
  border-bottom: 1px solid #2a3142 !important;
}

.b-l-dark {
  border-left: 1px solid #2a3142 !important;
}

.b-r-dark {
  border-right: 1px solid #2a3142 !important;
}

.b-warning {
  border: 1px solid #ffbc58 !important;
}

.b-t-warning {
  border-top: 1px solid #ffbc58 !important;
}

.b-b-warning {
  border-bottom: 1px solid #ffbc58 !important;
}

.b-l-warning {
  border-left: 1px solid #ffbc58 !important;
}

.b-r-warning {
  border-right: 1px solid #ffbc58 !important;
}

/*======= All-Borders-color css ends  ======= */
/*====== Border width css starts ======*/
.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

/*====== Border width css ends ======*/
.opacity-0 {
  opacity: 0;
}

.shadow-0 {
  box-shadow: none;
}

/**====== custom scrollbar css start ======**/
.digits {
  font-family: work-Sans, sans-serif;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e8ebf2;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(59, 130, 246, 0.1);
}

/**====== Custom scrollbar css end ======**/
/**====== Animation css Start ======**/
.line pre {
  font-size: 100%;
}

/**====== Animation css end ======**/
/**====== list style css Start ======**/
.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/
/**=====================
      Generic CSS Ends
==========================**/
/**=====================
     Chat CSS Start
==========================**/
.chat-box .toogle-bar {
  display: none;
}
.chat-box .people-list .search {
  position: relative;
}
.chat-box .people-list .search .form-control {
  background-color: #f1f4fb;
  border: 1px solid #f8f8f9;
}
.chat-box .people-list .search .form-control::placeholder {
  color: #aaaaaa;
}
.chat-box .people-list .search i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #e8ebf2;
}
.chat-box .people-list ul {
  padding: 0;
}
.chat-box .people-list ul li {
  padding-bottom: 20px;
}
.chat-box .people-list ul li:last-child {
  padding-bottom: 0;
}
.chat-box .user-image {
  float: left;
  width: 52px;
  height: 52px;
  margin-right: 5px;
}
.chat-box .about {
  float: left;
  margin-top: 5px;
  padding-left: 10px;
}
.chat-box .about .name {
  color: #2a3142;
  letter-spacing: 1px;
  font-weight: 600;
}
.chat-box .status {
  color: #aaaaaa;
  letter-spacing: 1px;
  font-size: 12px;
  margin-top: 5px;
}
.chat-box .status .chat-status {
  font-weight: 600;
  color: #313131;
}
.chat-box .status p {
  font-size: 14px;
}
.chat-box .chat-right-aside .chat .chat-header {
  padding: 15px;
  border-bottom: 1px solid #f8f8f9;
}
.chat-box .chat-right-aside .chat .chat-header img {
  float: left;
  width: 50px;
  height: 50px;
  box-shadow: 1px 1px 4px 1px #e8ebf2;
}
.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
  margin-top: 15px;
}
.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
  margin-right: 24px;
}
.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
  color: #777777;
  font-size: 25px;
  cursor: pointer;
}
.chat-box .chat-right-aside .chat .chat-msg-box {
  padding: 20px;
  overflow-y: auto;
  height: 560px;
  margin-bottom: 90px;
}
.chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
  margin-top: -35px;
}
.chat-box .chat-right-aside .chat .chat-msg-box .message-data {
  margin-bottom: 10px;
}
.chat-box .chat-right-aside .chat .chat-msg-box .message-data-time {
  letter-spacing: 1px;
  font-size: 12px;
  color: #aaaaaa;
  font-family: work-Sans, sans-serif;
}
.chat-box .chat-right-aside .chat .chat-msg-box .message {
  color: #2a3142;
  padding: 20px;
  line-height: 1.9;
  letter-spacing: 1px;
  font-size: 14px;
  margin-bottom: 30px;
  width: 50%;
  position: relative;
}
.chat-box .chat-right-aside .chat .chat-msg-box .my-message {
  border: 1px solid #f8f8f9;
  border-radius: 10px;
  border-top-left-radius: 0;
}
.chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  background-color: #f6f6f6;
  border-radius: 10px;
  border-top-right-radius: 0;
}
.chat-box .chat-right-aside .chat .chat-message {
  padding: 20px;
  border-top: 1px solid #f1f4fb;
  position: absolute;
  width: calc(100% - 15px);
  background-color: #ffffff;
  bottom: 0;
}
.chat-box .chat-right-aside .chat .chat-message .smiley-box {
  background: #eff0f1;
  padding: 10px;
  display: block;
  border-radius: 4px;
  margin-right: 0.5rem;
}
.chat-box .chat-right-aside .chat .chat-message .text-box {
  position: relative;
}
.chat-box .chat-right-aside .chat .chat-message .text-box .input-txt-bx {
  height: 50px;
  border: 2px solid #3b82f6;
  padding-left: 18px;
  font-size: 12px;
  letter-spacing: 1px;
}
.chat-box .chat-right-aside .chat .chat-message .text-box i {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: #e8ebf2;
  cursor: pointer;
}
.chat-box .chat-right-aside .chat .chat-message .text-box .btn {
  font-size: 16px;
  font-weight: 500;
}
.chat-box .chat-menu {
  border-left: 1px solid #f8f8f9;
}
.chat-box .chat-menu .tab-pane {
  padding: 0 15px;
}
.chat-box .chat-menu ul li .about .status i {
  font-size: 10px;
}
.chat-box .chat-menu .user-profile {
  margin-top: 30px;
}
.chat-box .chat-menu .user-profile .user-content h5 {
  margin: 25px 0;
}
.chat-box .chat-menu .user-profile .user-content hr {
  margin: 25px 0;
}
.chat-box .chat-menu .user-profile .user-content p {
  font-size: 16px;
}
.chat-box .chat-menu .user-profile .image {
  position: relative;
}
.chat-box .chat-menu .user-profile .image .icon-wrapper {
  position: absolute;
  bottom: 0;
  left: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 14px;
  box-shadow: 1px 1px 3px 1px #f8f8f9;
}
.chat-box .chat-menu .user-profile .image .avatar img {
  border-radius: 50%;
  border: 5px solid #f8f8f9;
}
.chat-box .chat-menu .user-profile .border-right {
  border-right: 1px solid #f8f8f9;
}
.chat-box .chat-menu .user-profile .follow {
  margin-top: 0;
}
.chat-box .chat-menu .user-profile .follow .follow-num {
  font-size: 22px;
  color: #000000;
}
.chat-box .chat-menu .user-profile .follow span {
  color: #1b252a;
  font-size: 14px;
  letter-spacing: 1px;
}
.chat-box .chat-menu .user-profile .social-media a {
  color: #aaaaaa;
  font-size: 15px;
  padding: 0 7px;
}
.chat-box .chat-menu .user-profile .chat-profile-contact p {
  font-size: 14px;
  color: #aaaaaa;
}
.chat-box .chat-menu .nav {
  margin-bottom: 20px;
}
.chat-box .chat-menu .nav-tabs .nav-item {
  width: 33.33%;
}
.chat-box .chat-menu .nav-tabs .nav-item a {
  padding: 15px !important;
  color: #aaaaaa !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-box .chat-menu .nav-tabs .nav-item .material-border {
  border-width: 1px;
  border-color: #3b82f6;
}
.chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
  color: #000000 !important;
}
.chat-box .chat-history .call-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
}
.chat-box .chat-history .total-time h2 {
  font-size: 50px;
  color: #eff0f1;
  font-weight: 600;
  margin-bottom: 30px;
}
.chat-box .chat-history .receiver-img {
  margin-top: 55px;
}
.chat-box .chat-history .receiver-img img {
  border-radius: 5px;
}
.chat-box .chat-history .call-icons {
  margin-bottom: 35px;
}
.chat-box .chat-history .call-icons ul li {
  width: 60px;
  height: 60px;
  border: 1px solid #f8f8f9;
  border-radius: 50%;
  padding: 12px;
}
.chat-box .chat-history .call-icons ul li + li {
  margin-left: 10px;
}
.chat-box .chat-history .call-icons ul li a {
  color: #999;
  font-size: 25px;
}

.status-circle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 40px;
  left: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.away {
  background-color: #ffbc58;
}

.online {
  background-color: #81ba00;
}

.offline {
  background-color: #e27272;
}

.chat-container .aside-chat-left {
  width: 320px;
}
.chat-container .chat-right-aside {
  width: 320px;
}

.call-chat-sidebar {
  max-width: 320px;
}

.call-chat-sidebar .card .card-body,
.chat-body .card .card-body {
  padding: 15px;
}

/**=====================
      Chat CSS Ends
==========================**/
/**=====================
      market-chart CSS Start
==========================**/
.market-chart {
  height: 303px;
}

/*=====================
    radial-bar CSS start
==========================*/
.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 70px;
  height: 70px;
  font-size: 18px;
  font-family: work-Sans, sans-serif;
  background-clip: content-box;
}
.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  content: attr(data-label);
  background-color: #fff;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 56px;
}
.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  z-index: 3;
  width: 60px;
  height: 60px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 56px;
}
.radial-bar.radial-bar-0 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(90deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-5 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(108deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-10 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(126deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-15 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(144deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-20 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(162deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #eff0f1), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(180deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-30 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(198deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-35 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(216deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-40 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(234deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-45 {
  background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(252deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-50 {
  background-image: linear-gradient(270deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-55 {
  background-image: linear-gradient(288deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-60 {
  background-image: linear-gradient(306deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-65 {
  background-image: linear-gradient(324deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-70 {
  background-image: linear-gradient(342deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-75 {
  background-image: linear-gradient(360deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-80 {
  background-image: linear-gradient(378deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-85 {
  background-image: linear-gradient(396deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-90 {
  background-image: linear-gradient(414deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-95 {
  background-image: linear-gradient(432deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar.radial-bar-100 {
  background-image: linear-gradient(450deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
}

.radial-bar-primary.radial-bar-0 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(90deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(108deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(126deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(144deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(162deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-25 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(180deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(198deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(216deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(234deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(252deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-50 {
  background-image: linear-gradient(270deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(288deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(306deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(324deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(342deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-75 {
  background-image: linear-gradient(360deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(378deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(396deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(414deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(432deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}
.radial-bar-primary.radial-bar-100 {
  background-image: linear-gradient(450deg, #3b82f6 50%, transparent 50%, transparent), linear-gradient(270deg, #3b82f6 50%, #eff0f1 50%, #eff0f1);
}

.market-chart .ct-series-a .ct-point {
  stroke: #3b82f6;
}
.market-chart .ct-series-a .ct-line {
  stroke: #3b82f6;
}
.market-chart .ct-series-a .ct-bar {
  stroke: #3b82f6;
}
.market-chart .ct-series-a .ct-slice-donut {
  stroke: #3b82f6;
}
.market-chart .ct-series-b .ct-point {
  stroke: #e27272;
}
.market-chart .ct-series-b .ct-line {
  stroke: #e27272;
}
.market-chart .ct-series-b .ct-bar {
  stroke: #e27272;
}
.market-chart .ct-series-b .ct-slice-donut {
  stroke: #e27272;
}

.dropzone-custom .dropzone {
  text-align: center;
}
.dropzone-custom .dropzone .fa-cloud-upload {
  font-size: 50px;
  color: #3b82f6;
}
.dropzone-custom .dropzone h4 {
  font-size: 18px;
}

.needs-validation .custom-select {
  background: #ffffff;
  font-size: 14px;
}
.needs-validation .form-control {
  border-radius: 0.25rem;
  padding: 0.48rem 0.75rem;
}
.needs-validation .form-control.error {
  border-color: #ff3c7e;
  background-color: #ffeff4;
}
.needs-validation .form-control.valid {
  border-color: #37c936;
  background-color: #ebfaeb;
  color: #278d26;
}
.needs-validation .ng-select {
  border-radius: 0.25rem;
  padding: 0;
}
.needs-validation .ng-select.error {
  border: 1px solid #ff3c7e;
  background-color: #ffeff4;
}
.needs-validation .ng-select.valid {
  border-color: #37c936;
  background-color: #ebfaeb;
  color: #278d26;
}

.license-key[disabled] {
  cursor: not-allowed;
}

.jsgrid-cell,
.jsgrid-grid-body {
  border: 1px solid #ebf1ff;
}

.jsgrid .jsgrid-pager-container {
  text-align: right;
}
.jsgrid .jsgrid-pager [class*=jsgrid-pager] {
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  border: 1px solid #f8f8f9;
}
.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button {
  margin-right: -5px;
}

.jsgrid-alt-row .jsgrid-cell {
  background-color: rgba(241, 244, 251, 0.5);
  text-align: center;
}

.jsgrid-row > .jsgrid-cell {
  text-align: center;
}

.jsgrid-filter-row .jsgrid-cell {
  background-color: #f8f8f9;
  text-align: center;
}

.jsgrid-header-row .jsgrid-header-cell {
  background-color: #f1f4fb;
  text-align: center;
  padding: 0.75rem;
}

.jsgrid-selected-row > .jsgrid-cell {
  background-color: rgba(241, 244, 251, 0.5);
  border-color: #f1f4fb;
}

.checkbox label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox .form-check-input {
  margin-top: 13px;
  margin-bottom: 10px;
}

.form-row .form-control {
  font-size: 14px;
  letter-spacing: 1px;
}
.form-row .custom-select {
  font-size: 14px;
  letter-spacing: 1px;
}

/*=====================
   bulk-pruduct CSS start
==========================*/
.bulk-pruduct .card .card-body p {
  font-weight: bold;
}

/**=====================
   Datepicker CSS Start
==========================**/
.datepicker {
  box-shadow: 0 4px 14px rgba(59, 130, 246, 0.15);
}

.datepicker--day-name {
  color: #000000;
  font-weight: bold;
}

.datepicker--cell.-current- {
  color: #000000;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid #3b82f6;
}
.datepicker--cell.-focus- {
  background: #3b82f6;
  color: #ffffff;
}
.datepicker--cell.-selected- {
  background: #3b82f6;
}

.daterangepicker.ltr {
  color: #898989;
  z-index: 8;
}
.daterangepicker.ltr tr {
  font-size: 13px;
}
.daterangepicker.ltr tr td {
  height: 34px;
  width: 34px;
}
.daterangepicker.ltr tr td.in-range {
  background-color: #fafafa;
}
.daterangepicker.ltr tr td.active {
  background-color: #3b82f6;
}
.daterangepicker.ltr .ranges {
  float: none;
}
.daterangepicker.ltr .ranges li {
  color: #3b82f6;
}
.daterangepicker.ltr .ranges li:hover {
  background-color: #3b82f6;
  border: 1px solid #3b82f6;
  color: #ffffff;
}
.daterangepicker.ltr .ranges li.active {
  background-color: #3b82f6;
  border: 1px solid #3b82f6;
  color: #ffffff;
}
.daterangepicker.ltr .calendar-time select {
  color: #898989;
}

.datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}

.date-range-picker .theme-form {
  margin-bottom: 30px;
}
.date-range-picker > div:last-child .theme-form {
  margin-bottom: 0px;
}

/**=====================
    Datepicker CSS End
==========================**/
/*=====================
    Responsive CSS start
==========================*/
@media only screen and (max-width: 1660px) {
  .peity-chart-dashboard .peity {
    height: 148px;
  }
  .products-table table tr td:first-child {
    min-width: 268px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .peity-chart-dashboard .peity {
    height: 120px;
  }
  .flot-chart-container {
    height: 350px;
  }
  .product-adding .col-xl-5 {
    flex: 0 0 36%;
    max-width: 36%;
  }
  .product-adding .col-xl-7 {
    flex: 0 0 64%;
    max-width: 64%;
  }
}
@media only screen and (max-width: 1366px) {
  .order-responsive datatable-body-cell,
  .order-responsive datatable-header-cell {
    width: 160px !important;
  }
  .order-responsive .datatable-row-center {
    overflow: auto;
  }
  .order-responsive datatable-selection {
    white-space: nowrap;
  }
  .order-graph .ct-4.flot-chart-container {
    height: auto;
  }
  .order-graph .ct-4 x-chartist svg {
    height: 360px !important;
  }
  .user-image table tr th:nth-child(6),
  .user-image table tr td:nth-child(6) {
    width: 300px !important;
  }
  .page-main-header .main-header-right .nav-right > ul > li:first-child {
    width: 32%;
  }
  .peity-chart-dashboard .peity {
    height: 180px;
  }
  .xl-space {
    margin-top: 30px;
  }
  .chart-vertical-center #myDoughnutGraph,
  .chart-vertical-center #myPolarGraph {
    height: 180px !important;
  }
  .product-adding .add-product-form {
    margin-top: 30px;
  }
  .user-list table tr td:nth-child(5),
  .user-list table tr th:nth-child(5) {
    width: 195px !important;
  }
  .translation-list table tr td,
  .translation-list table tr th {
    width: 162px !important;
  }
  .translation-list table tr td:first-child, .translation-list table tr td:nth-child(2),
  .translation-list table tr th:first-child,
  .translation-list table tr th:nth-child(2) {
    width: 274px !important;
  }
  .order-datatable table {
    display: block;
  }
  .order-datatable table tr th,
  .order-datatable table tr td {
    min-width: 121px;
  }
  .order-datatable table tr th:nth-child(2),
  .order-datatable table tr td:nth-child(2) {
    min-width: 150px;
  }
  .vendor-table table {
    display: block;
  }
  .vendor-table table tr th,
  .vendor-table table tr td {
    min-width: 112px;
  }
  .vendor-table table tr th:first-child,
  .vendor-table table tr td:first-child {
    min-width: 150px;
  }
  .media-table table tr th:nth-child(3),
  .media-table table tr td:nth-child(3) {
    width: 146px !important;
  }
  .media-table table tr th:last-child,
  .media-table table tr td:last-child {
    width: 248px !important;
  }
}
@media only screen and (max-width: 1199px) {
  .product-right h2 {
    font-size: 20px;
  }
  .product-page-details {
    margin-top: 30px;
  }
  .menu-list-table table tr th,
  .menu-list-table table tr td {
    width: 300px !important;
  }
  .product-physical table tr th,
  .product-physical table tr td {
    width: 70px !important;
  }
  .product-physical table tr th:nth-child(2),
  .product-physical table tr td:nth-child(2) {
    width: 173px !important;
  }
  .product-physical table tr th:nth-child(5),
  .product-physical table tr td:nth-child(5) {
    width: 100px !important;
  }
  .category-table table tr td,
  .category-table table tr th {
    width: 120px !important;
  }
  .category-table table tr td:nth-child(2),
  .category-table table tr th:nth-child(2) {
    width: 500px !important;
  }
  .media-table table tr td:last-child,
  .media-table table tr th:last-child {
    width: 370px !important;
  }
  .order-table table tr td:nth-child(4),
  .order-table table tr th:nth-child(4) {
    width: 230px !important;
  }
  .user-list table tr th,
  .user-list table tr td {
    width: 200px !important;
  }
  .transactions table tr td:nth-child(4),
  .transactions table tr th:nth-child(4) {
    width: 100px !important;
  }
  .transactions table tr td:first-child,
  .transactions table tr th:first-child {
    width: 100px !important;
  }
  .transactions table tr td:nth-child(2),
  .transactions table tr th:nth-child(2) {
    width: 150px !important;
  }
}
@media screen and (max-device-width: 1366px) and (min-device-width: 1200px) {
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .product-adding .col-xl-5,
  .product-adding .col-xl-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .product-adding .add-product-form {
    margin-top: 30px;
  }
  .category-table table tr td:first-child,
  .category-table table tr th:first-child {
    width: 90px !important;
  }
  .order-table table tr td:nth-child(2),
  .order-table table tr th:nth-child(2) {
    width: 70px !important;
  }
  .user-list table tr td:first-child,
  .user-list table tr th:first-child {
    width: 130px !important;
  }
  .profile-table table tr th,
  .profile-table table tr td {
    width: 200px !important;
  }
}
@media only screen and (max-width: 991px) {
  .report-employee .flot-chart-container {
    height: 310px;
  }
  .authentication-box {
    padding: 30px;
  }
  .authentication-box .container .bg-primary {
    padding: 40px;
  }
  ng2-smart-table ng2-smart-table-pager {
    margin-top: 20px;
  }
  .btn-popup {
    margin-bottom: 20px;
  }
  .order-graph .order-graph-bottom {
    margin-top: 20px;
  }
  .xl-space {
    margin-top: 20px;
  }
  .card .card-body,
  .card .card-header {
    padding: 20px;
  }
  .card .card-body .card-header-right,
  .card .card-header .card-header-right {
    right: 11px;
    top: 15px;
  }
  .page-wrapper .page-main-header.open {
    z-index: 11;
  }
  .right-sidebar {
    top: 133px;
  }
  .offcanvas .page-wrapper .page-body-wrapper .page-body:before {
    left: 0;
  }
  .page-wrapper .page-body-wrapper .page-header .row h3 {
    font-size: 22px;
  }
  .page-wrapper .page-body-wrapper .page-header .row .pull-right {
    float: none;
    margin-top: 5px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar {
    top: 60px !important;
    height: calc(100vh - 60px) !important;
  }
  .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
    margin-left: 0;
    margin-top: 60px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body .page-header .row .page-header-left {
    display: inherit;
  }
  .page-wrapper .page-body-wrapper .sidebar {
    height: calc(100vh - 60px);
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body {
    margin-top: 60px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.gradient-line-1:after {
    bottom: -45px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.small-line:after {
    bottom: -28px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.medium-line:after {
    bottom: -41px;
  }
  .page-wrapper .page-body-wrapper footer {
    margin-left: 0;
  }
  .page-wrapper .page-main-header {
    height: 60px;
    margin-left: 0;
    width: 100%;
    z-index: 11;
  }
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    height: 23px;
    margin-top: 0;
    margin-right: 15px;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right {
    position: unset;
    padding: 0;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul {
    top: 60px;
    position: absolute;
    z-index: -1;
    background-color: #fff;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
    -webkit-box-shadow: 0 2px 2px 2px #efefef;
    box-shadow: 0 2px 2px 2px #efefef;
    width: 100%;
    left: 0;
    padding: 0 40px;
    -webkit-transform: translateY(-35px) scaleY(0);
    transform: translateY(-35px) scaleY(0);
    opacity: 0;
    visibility: hidden;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
    margin: 10px 0;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 9px;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle svg circle {
    color: #3b82f6;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul.open {
    z-index: 1;
    opacity: 1;
    -webkit-transform: translateY(0px) scaleY(1);
    transform: translateY(0px) scaleY(1);
    visibility: visible;
  }
  .page-header {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .page-header .row .page-header-left .breadcrumb {
    margin-top: 5px;
    margin-left: 0;
  }
  #carousel-container > .current-figure > #current-image,
  #carousel-container > .current-figure > #current-image-legacy {
    margin-bottom: 20px;
  }
  .product-right h2 {
    margin-top: 15px;
    font-size: 20px;
  }
  .product-page-details {
    text-align: left !important;
    margin-top: 0;
  }
  .product-page-main .owl-stage-outer {
    margin-top: 20px;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px;
  }
  .add-product img {
    width: 460px;
  }
  .zoomContainer {
    top: 209px !important;
  }
  .product-adding .add-product-form {
    margin-top: 20px;
  }
  .tab-coupon {
    margin-bottom: 20px;
  }
  .deactivate-account {
    margin-top: 20px;
  }
  .sell-graph canvas {
    height: 312px !important;
  }
  .transcation-datatable table th:nth-child(3),
  .transcation-datatable table td:nth-child(3) {
    width: 230px !important;
  }
}
@media only screen and (max-width: 767px) {
  .report-employee .flot-chart-container.report-full svg {
    width: 750px !important;
  }
  .card-right {
    margin-left: 0;
  }
  .card-right .card {
    padding-left: 0;
  }
  .authentication-box .form-footer,
  .authentication-box .form-button {
    text-align: center;
  }
  .authentication-box .form-footer > span,
  .authentication-box .form-button > span {
    padding: 0;
    left: 0;
    right: 0;
  }
  .datepicker-here {
    width: auto !important;
  }
  .input-grp-p {
    padding: 0 15px;
  }
  .created-width table tr th:last-child,
  .created-width table tr td:last-child {
    width: 200px !important;
  }
  .custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    min-width: 32px !important;
    line-height: 34px !important;
  }
  .page-main-header .main-header-right .nav-right > ul > li:first-child {
    width: 40%;
  }
  .products-table table tr td:first-child,
  .products-table table tr th:first-child {
    min-width: 280px;
  }
  .products-table table tr td:nth-child(3),
  .products-table table tr th:nth-child(3) {
    min-width: 110px;
  }
  .page-wrapper .page-body-wrapper .page-header .row .col {
    flex-basis: unset;
  }
  .page-wrapper .page-body-wrapper .page-header .row .col .pull-right {
    float: none;
    margin-top: 20px;
  }
  .page-wrapper .page-body-wrapper footer .row div {
    text-align: center;
  }
  .page-wrapper .page-body-wrapper footer .row div .pull-right {
    float: none;
  }
  .footer-fix .pull-right {
    float: none !important;
    text-align: center !important;
  }
  .product-physical table tr th,
  .product-physical table tr td {
    width: 90px !important;
  }
  .product-physical table tr th:nth-child(2),
  .product-physical table tr td:nth-child(2) {
    width: 196px !important;
  }
  .add-product-form .form-group .form-control,
  .add-product-form .form-group select {
    width: 100% !important;
    margin: 0 !important;
  }
  .add-product-form .input-group .touchspin {
    width: 30% !important;
  }
  .add-product-form .radio-animated label {
    margin-bottom: 0;
  }
  .add-product-form .editor-space {
    padding: 0 15px;
  }
  .add-product-form textarea {
    margin: 0 15px;
  }
  .add-product img {
    width: 400px;
  }
  .digital-add textarea {
    margin: 0 !important;
  }
  .digital-add .form-group select,
  .digital-add .form-group .form-control {
    width: 100% !important;
  }
  .digital-add .form-group label {
    margin-bottom: 0 !important;
  }
  .digital-product table tr th,
  .digital-product table tr td {
    width: 120px !important;
  }
  .report-table table tr th,
  .report-table table tr td {
    width: 150px !important;
  }
  .needs-validation .form-group.row {
    margin-left: 0;
    margin-right: 0;
  }
  .needs-validation .form-group .form-control,
  .needs-validation .form-group select {
    width: 95%;
    margin: 0 auto;
  }
  .needs-validation .form-group .checkbox {
    padding-left: 15px;
  }
  .needs-validation .form-group label {
    margin-bottom: 4px;
  }
  .needs-validation .radio-animated label {
    margin-bottom: 0;
  }
  .needs-validation .editor-space {
    padding: 0 15px;
  }
  .needs-validation textarea {
    margin: 0 15px;
  }
  .dataTables_wrapper .dataTables_paginate {
    float: none !important;
    margin-top: 20px !important;
  }
  .dataTables_wrapper .dataTables_length label,
  .dataTables_wrapper .dataTables_filter label {
    float: none !important;
  }
  .dataTables_wrapper table.dataTable {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 577px) {
  footer {
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .report-employee .flot-chart-container.report-full svg {
    width: 480px !important;
  }
  .profile-tabs {
    display: block !important;
  }
  .vendor-list img {
    margin-right: 15px;
  }
  .created-width table tr th:last-child,
  .created-width table tr td:last-child {
    width: 250px !important;
  }
  .custom-datatable input {
    width: 56% !important;
  }
  .ng2-smart-filter input,
  .ng2-smart-filter select {
    width: 100% !important;
  }
  .order-graph .ct-4 x-chartist svg {
    height: 250px !important;
  }
  .justify-content-start {
    justify-content: inherit !important;
  }
  .custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    min-width: 22px !important;
    line-height: 27px !important;
  }
  ng2-smart-table ng2-smart-table-pager {
    margin-top: 15px;
  }
  .btn-popup {
    margin-bottom: 15px;
  }
  .static-top-widget div.align-self-center svg {
    width: 20px;
    height: 20px;
  }
  .latest-order-table table tr td {
    min-width: 100px;
  }
  .latest-order-table table tr td + td {
    min-width: 150px;
  }
  .latest-order-table table tr td:nth-child(3) {
    min-width: 200px;
  }
  .card {
    margin-bottom: 15px;
  }
  .card .card-header {
    padding: 15px;
  }
  .card .card-header h5 {
    font-size: 17px;
  }
  .card .card-header .card-header-right {
    right: 6px;
    top: 10px;
  }
  .card .card-body {
    padding: 15px;
  }
  .sm-order-space,
  .xl-space {
    margin-top: 15px;
  }
  .order-graph .order-graph-bottom {
    margin-top: 15px;
  }
  .order-graph .order-graph-bottom h6 {
    margin-right: 0;
  }
  .order-graph .order-graph-bottom .media {
    margin-bottom: 15px;
  }
  .offcanvas .page-wrapper .page-body-wrapper .page-header .row h3 {
    font-size: 22px;
  }
  .offcanvas .page-wrapper .page-body-wrapper .page-body:before {
    left: 0;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .d-sm-none:after {
    display: none;
  }
  ul.notification-dropdown.onhover-show-div {
    width: 284px;
    right: -112px;
  }
  ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
    right: 135px !important;
  }
  .page-wrapper .page-main-header .main-header-right {
    padding: 0 15px;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul {
    padding: 0 0;
    justify-content: flex-start;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child {
    width: auto;
    margin: 0;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:before {
    display: none;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:after {
    display: none;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-group {
    margin-bottom: 0;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext.open {
    -webkit-transform: translateY(0px) scaleY(1);
    transform: translateY(0px) scaleY(1);
    opacity: 1;
    visibility: visible;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext {
    top: 60px;
    position: absolute;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
    left: 0;
    background-color: #fff;
    -webkit-transform: translateY(-35px) scaleY(0);
    transform: translateY(-35px) scaleY(0);
    opacity: 0;
    visibility: hidden;
    width: 180px;
    padding: 10px 10px 10px 15px;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .mobile-search svg {
    color: #3b82f6;
  }
  .page-wrapper .search-form .form-group {
    margin-right: 0;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.gradient-line-1:after {
    bottom: -41px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.small-line:after {
    bottom: -19px;
    height: 12px;
  }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.medium-line:after {
    bottom: -34px;
  }
  .page-wrapper .page-body-wrapper .page-body {
    padding: 0;
  }
  .page-wrapper .page-body-wrapper .page-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  #carousel-container > .current-figure > #current-image,
  #carousel-container > .current-figure > #current-image-legacy {
    margin-bottom: 15px;
  }
  .profile-details img {
    margin-bottom: 15px;
  }
  .project-status {
    margin-top: 15px;
  }
  .project-status .media {
    margin-top: 15px;
  }
  .tab2-card ul {
    display: inherit;
    margin-bottom: 15px;
  }
  .tab2-card ul li {
    display: block;
  }
  .tab2-card ul li a {
    text-align: center;
  }
  .profile-table table tbody tr td:first-child {
    min-width: 150px;
  }
  .account-setting h5 {
    margin-bottom: 15px;
  }
  .deactivate-account {
    margin-top: 15px;
  }
  .deactivate-account .btn {
    margin-top: 15px;
  }
  .sm-label-radio {
    margin-bottom: 4px !important;
  }
  .permission-block .attribute-blocks .form-group {
    padding-bottom: 15px;
  }
  .needs-validation textarea {
    margin: 0 10px;
  }
  .needs-validation h4 {
    margin-bottom: 15px;
  }
  .needs-validation .permission-block .attribute-blocks .row {
    padding-left: 15px;
  }
  .needs-validation .permission-block .attribute-blocks + .attribute-blocks h5 {
    margin-top: 20px;
  }
  .needs-validation .radio_animated {
    margin: 0 6px 0 0;
  }
  .needs-validation .radio-animated label {
    margin-right: 15px;
  }
  .tab-coupon {
    display: grid;
  }
  .translation-list table tr td:nth-child(3), .translation-list table tr td:nth-child(4),
  .translation-list table tr th:nth-child(3),
  .translation-list table tr th:nth-child(4) {
    width: 180px !important;
  }
  .product-list table tr td,
  .product-list table tr th {
    width: 100px !important;
  }
  .product-list table tr td:nth-child(2),
  .product-list table tr th:nth-child(2) {
    width: 250px !important;
  }
  .product-list table tr td:nth-child(3),
  .product-list table tr th:nth-child(3) {
    width: 120px !important;
  }
  .digital-product table tr td:nth-child(2),
  .digital-product table tr th:nth-child(2) {
    width: 120px !important;
  }
  .product-buttons {
    padding: 0 15px;
  }
  .add-product-form .form-group .form-control {
    width: 93%;
    margin: 0 auto;
  }
  .add-product-form .form-group label {
    padding: 0;
  }
  .add-product-form .qty-box {
    width: 162px;
  }
  .add-product-form .qty-box .input-group .form-control {
    width: 80px;
  }
  .add-product-form .description-sm {
    padding: 0 !important;
  }
  .zoomContainer {
    top: 184px !important;
  }
  .add-product ul li .box-input-file,
  .add-product ul li .imgUpload {
    width: 30px;
    height: 30px;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 15px !important;
    margin-left: 0 !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 1px 4px !important;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 15px;
  }
  .dataTables_wrapper table.dataTable {
    margin-top: 15px !important;
  }
}
@media (max-width: 420px) {
  .product-right .timer span {
    width: 45px;
  }
}
@media only screen and (max-width: 360px) {
  .authentication-box {
    padding: 30px 15px;
  }
  .authentication-box .forgot-pass {
    float: none;
    padding-top: 10px !important;
  }
  .authentication-box .container .form-group {
    margin-bottom: 1rem;
  }
  .authentication-box .container h3 {
    font-size: 20px;
  }
  .authentication-box .container p {
    line-height: 1.6;
  }
  .authentication-box .container .svg-icon {
    padding: 17px;
    height: 90px;
    width: 90px;
    margin-bottom: 25px;
  }
  .authentication-box .container .svg-icon svg {
    height: 50px;
  }
  .authentication-box .container .bg-primary {
    padding: 30px 15px;
  }
  .input-grp-p {
    padding: 0 7px;
  }
  .custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    min-width: 16px !important;
    line-height: 22px !important;
    font-size: 13px;
  }
  .nav-menus .notification-badge {
    right: 0;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
    padding: 0 13px;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li .dot {
    right: 13px;
  }
  .footer {
    padding-left: 0;
    padding-right: 0 !important;
  }
  .footer p {
    font-size: 12px;
  }
  .add-product img {
    width: 300px;
  }
  .product-page-main .btn {
    padding: 5px 12px;
  }
}
@media only screen and (max-height: 800px) {
  .right-sidebar .chat-box .friend-list {
    max-height: calc(100vh - 150px);
    overflow: scroll;
  }
}
/*=====================
  Responsive CSS End
==========================*/
/*Datatable pagination*/
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #333 !important;
  border: 1px solid #86d3fc !important;
  border-radius: 50%;
  background-color: #86d3fc !important;
  background: linear-gradient(to bottom, #86d3fc 0%, #86d3fc 100%) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: none !important;
  color: #facd03 !important;
  background: transparent !important;
}

.modal-header {
  display: block !important;
}

.mat-btn {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 48px !important;
}

.fa-cloud-upload {
  font-size: 20px !important;
  position: relative;
  right: 10.5px !important;
  top: 1px !important;
}

.mar-bot-20 {
  margin-bottom: 20px !important;
}

.mar-bot-70 {
  margin-bottom: 70px !important;
}

.p-chips {
  width: 100% !important;
}
.p-chips .p-chips-multiple-container {
  width: 100% !important;
}

.p-dropdown {
  width: 100% !important;
}

p-celleditor {
  width: 100% !important;
}

.p-fieldset .p-fieldset-legend {
  padding: 1.3rem !important;
  color: #343a40 !important;
  background: #f8f9fa !important;
  border-radius: 0 !important;
  font-size: 18px !important;
}
.p-fieldset .p-fieldset-legend span {
  display: block !important;
}

.p-calendar {
  display: flex;
}
.p-calendar span {
  width: 100%;
}
.p-calendar input {
  border-radius: 6px 0px 0px 6px;
}
.p-calendar button {
  border-radius: 0px 6px 6px 0px;
}

#quantity-form p-inputnumber {
  width: 100px;
}
#quantity-form p-inputnumber span {
  width: 35px;
}
#quantity-form p-inputnumber span button {
  width: 2rem;
}
#quantity-form p-inputnumber span button.p-inputnumber-button-up {
  padding: 5px;
  border-radius: 0px 5px 5px 0px;
}
#quantity-form p-inputnumber span button.p-inputnumber-button-down {
  padding: 5px;
  border-radius: 5px 0px 0px 5px;
}
#quantity-form p-inputnumber span input {
  width: 35px;
  border-radius: 0px;
}

.custom-theme {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer;
}

body.rtl {
  direction: rtl;
}
body.rtl .m-r-15 {
  margin-left: 15px;
  margin-right: unset;
}
body.rtl .mr-2, body.rtl .mx-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}
body.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
}
body.rtl .pl-0, body.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: unset !important;
}
body.rtl .m-r-10 {
  margin-left: 10px;
  margin-right: unset;
}
body.rtl .radio_animated, body.rtl .checkbox_animated {
  margin: 0 0 0 1rem;
}
body.rtl .digital-add .form-group .radio_animated {
  margin-left: 8px;
  margin-right: unset;
}
body.rtl .needs-validation .form-group {
  text-align: right;
}
body.rtl .needs-validation .form-group .checkbox {
  padding-right: 0;
  padding-left: unset;
}
body.rtl .needs-validation .permission-block .attribute-blocks {
  padding-right: 15px;
  padding-left: unset;
  text-align: right;
}
body.rtl .needs-validation .permission-block .attribute-blocks .row {
  padding-right: 20px;
  padding-left: unset;
}
body.rtl .needs-validation .checkbox {
  right: 0;
  margin-left: -16px;
}
body.rtl .needs-validation .checkbox label {
  padding-right: 16px;
  padding-left: unset;
}
body.rtl .needs-validation .checkbox label::before {
  right: 0;
  left: unset;
  margin-left: unset;
  margin-right: -16px;
}
body.rtl .needs-validation .radio-animated label {
  margin-left: 20px;
  margin-right: unset;
}
body.rtl .modal-footer > :not(:last-child) {
  margin-left: 0.25rem;
  margin-right: unset;
}
body.rtl .jsgrid-button + .jsgrid-button {
  margin-right: 5px;
  margin-left: unset;
}
body.rtl .custom-theme {
  left: 0;
  right: unset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
body.rtl .text-right {
  text-align: left !important;
}
body.rtl .br-wrapper {
  text-align: right;
}
body.rtl .card .card-header .card-header-right {
  left: 20px;
  right: unset;
}
body.rtl .card .card-header .card-header-right .card-option {
  text-align: left;
}
body.rtl .card .card-header .card-header-right .card-option.list-unstyled {
  padding-right: 0;
  padding-left: unset;
}
body.rtl code[class*=language-], body.rtl pre[class*=language-] {
  text-align: right;
}
body.rtl .code-box-copy__btn {
  right: unset;
  left: 11px;
}
body.rtl table thead tr th, body.rtl table thead tr td, body.rtl table tbody tr th, body.rtl table tbody tr td {
  text-align: right;
}
body.rtl .pull-right {
  float: left;
}
body.rtl .status-circle {
  right: 40px;
  left: unset;
}
body.rtl .right-sidebar.show {
  left: 0;
  right: unset;
}
body.rtl .right-sidebar .friend-list-search .fa {
  left: 35px;
  right: unset;
}
body.rtl .right-sidebar .chat-box .about {
  float: right;
  padding-right: 10px;
  padding-left: unset;
  text-align: right;
}
body.rtl .right-sidebar .chat-box .friend-list {
  text-align: right;
}
body.rtl .right-sidebar .chat-box .user-image {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}
body.rtl .nav-menus .search-form input {
  padding: 10px 70px 10px 10px;
}
body.rtl ul.notification-dropdown.onhover-show-div li {
  text-align: right;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6, body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
  text-align: center;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li {
  text-align: right;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
  margin-right: 15px;
  margin-left: unset;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a {
  padding-right: 10px;
  padding-left: unset;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-circle {
  right: 0;
  left: unset;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
  margin-right: unset;
  margin-left: 14px;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
  margin-right: 255px;
  margin-left: unset;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open {
  margin-right: -260px;
  margin-left: unset;
}
body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body, body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open ~ footer {
  margin-right: 0;
}
body.rtl .page-wrapper .page-body-wrapper .page-header .row h3 {
  text-align: right;
}
body.rtl .page-wrapper .page-body-wrapper footer {
  margin-right: 255px;
  margin-left: unset;
}
body.rtl .page-wrapper .page-body-wrapper footer p i {
  margin-right: 5px;
  margin-left: unset;
}
body.rtl .page-main-header {
  margin-right: 255px;
  margin-left: unset;
}
body.rtl .page-main-header .main-header-right .nav-right {
  text-align: left;
}
body.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li svg {
  margin-right: unset;
  margin-left: 10px;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li {
  text-align: right;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li:before {
  right: 0;
  left: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li .dot {
  left: 17px;
  right: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li .media .dotted-animation {
  left: -3px;
  right: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li .media .dotted-animation .main-circle {
  left: 6px;
  right: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li .media .dotted-animation .animate-circle {
  left: -4px;
  right: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:before {
  right: 10px;
  left: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:after {
  right: 10px;
  left: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li.onhover-dropdown:last-child {
  padding-left: 0;
  padding-right: 20px;
  border-right: none;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li:first-child {
  border-right: none;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:after {
  right: 22px;
  left: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:before {
  right: 51px;
  left: unset;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li:last-child {
  padding-right: 20px;
  padding-left: 0;
}
body.rtl .page-main-header .main-header-right .nav-right > ul > li i.ml-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
body.rtl .page-main-header .main-header-right .nav-right .language-dropdown {
  text-align: right;
}
body.rtl .page-main-header .main-header-right .nav-right .language-dropdown li a i {
  margin-left: 10px;
}
body.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
  margin-right: unset;
  margin-left: 10px;
}
body.rtl .page-main-header .main-header-right .mobile-sidebar {
  padding-right: 20px;
}
body.rtl .page-main-header.open {
  margin-right: 0;
}
body.rtl ul.notification-dropdown.onhover-show-div {
  right: initial;
  left: -30px;
  padding: 0;
}
body.rtl ul.notification-dropdown.onhover-show-div:before {
  right: inherit !important;
  left: 35px !important;
}
body.rtl ul.notification-dropdown.onhover-show-div:after {
  right: inherit !important;
  left: 35px !important;
}
body.rtl ul.notification-dropdown.onhover-show-div li {
  margin-right: 0 !important;
}
body.rtl ul.notification-dropdown.onhover-show-div li span.badge {
  margin-left: unset !important;
}
body.rtl ul.notification-dropdown.onhover-show-div li span svg {
  margin-right: unset;
  margin-left: 10px;
}
body.rtl ul.notification-dropdown.onhover-show-div li .notification-icon {
  margin-left: 20px;
  margin-right: unset;
}
body.rtl .owl-carousel {
  direction: ltr;
}
body.rtl .media .media-body {
  text-align: right;
}
body.rtl .order-graph .order-graph-bottom h6 {
  margin-right: 15px !important;
  margin-left: 30px;
}
body.rtl .latest-order-table .btn {
  float: right;
}
body.rtl .product-physical table tr th:nth-child(2), body.rtl .product-physical table tr td:nth-child(2) {
  text-align: right;
}
body.rtl .product-page-details .color-variant, body.rtl .product-page-details .size-box, body.rtl .product-right .color-variant, body.rtl .product-right .size-box {
  text-align: right;
}
body.rtl .add-product-form .qty-box .input-group .input-group-append .btn {
  border-right: none !important;
  border-left: 1px solid #dddddd !important;
}
body.rtl .add-product-form .qty-box .input-group .btn-primary.bootstrap-touchspin-down {
  border-left: none !important;
  border-right: 1px solid #dddddd !important;
}
body.rtl .add-product-form .offset-sm-4 {
  text-align: right;
}
body.rtl .timer {
  float: right;
}
body.rtl .timer ~ .m-t-15 {
  clear: both;
  text-align: right;
  padding-top: 15px;
}
body.rtl .timer span .padding-l {
  right: 35px;
  left: unset;
  padding-right: 0;
}
body.rtl .product-list table tr td, body.rtl .product-list table tr th, body.rtl .report-table table tr td, body.rtl .report-table table tr th {
  text-align: right !important;
}
body.rtl .product-list table tr td:last-child, body.rtl .product-list table tr th:last-child, body.rtl .report-table table tr td:last-child, body.rtl .report-table table tr th:last-child {
  text-align: center !important;
}
body.rtl .dropzone .dz-preview .dz-error-mark, body.rtl .dropzone .dz-preview .dz-success-mark {
  left: unset;
  right: 48%;
  margin-right: -27px;
  margin-left: unset;
}
body.rtl .dropzone.dz-clickable .dz-message * {
  text-align: center;
}
body.rtl .digital-add .col-form-label {
  display: block;
  text-align: right;
}
body.rtl .dataTables_wrapper .dataTables_filter input[type=search] {
  margin-right: 10px;
  margin-left: unset;
}
body.rtl .dataTables_wrapper table.dataTable .vendor-list img {
  margin-left: 20px;
  margin-right: unset;
}
body.rtl .category-table table tr td:nth-child(3), body.rtl .category-table table tr th:nth-child(3) {
  text-align: right;
}
body.rtl .media-table table tr td:last-child, body.rtl .media-table table tr th:last-child {
  text-align: right !important;
}
body.rtl .profile-details h5 {
  text-align: center;
}
body.rtl .account-setting {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  body.rtl .page-wrapper .page-main-header {
    margin-right: 0;
  }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body, body.rtl .page-wrapper .page-body-wrapper .page-sidebar ~ footer {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  body.rtl .needs-validation .form-group .checkbox {
    padding-right: 15px;
    padding-left: unset;
  }
  body.rtl .page-wrapper .page-body-wrapper footer p {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  body.rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }
}

/**=====================
    30. Data-table CSS Start
==========================**/
.selection-datatable .table-responsive ng2-smart-table-pager {
  margin-top: 0 !important;
}

.custom-datatable input {
  padding: 8px;
  margin-bottom: 30px;
  border: 1px solid #eff0f1;
  width: 30%;
  color: #999;
}
.custom-datatable input::placeholder {
  color: #999;
}
.custom-datatable .datatable-icon-right:before {
  content: "\f101";
  font-family: FontAwesome;
  font-size: 14px;
}
.custom-datatable .datatable-icon-left:before {
  content: "\f100";
  font-family: FontAwesome;
  font-size: 14px;
}
.custom-datatable .ngx-datatable .datatable-checkbox {
  margin-bottom: 0;
}
.custom-datatable .ngx-datatable .datatable-checkbox input {
  width: auto;
  margin-bottom: 0;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-header {
  border: 1px solid #e8efff;
  border-bottom: none;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: none;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body {
  border: 1px solid #e8efff;
  border-top: none;
  margin-bottom: 6px;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-selection .datatable-scroll .datatable-row-wrapper:last-child {
  border-bottom: none;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row input {
  margin-bottom: 0;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: rgba(59, 130, 246, 0.05);
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: rgba(241, 244, 251, 0.5);
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even:hover {
  background-color: rgba(241, 244, 251, 0.5);
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even.active {
  background-color: rgba(59, 130, 246, 0.05);
}
.custom-datatable .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-odd:hover {
  background-color: rgba(241, 244, 251, 0.3);
}
.custom-datatable .ngx-datatable.bootstrap .datatable-footer {
  background: #ffffff;
  color: inherit;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin-right: 0;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 0;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a {
  background-color: #3b82f6;
  color: #ffffff;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  border: 1px solid #3b82f6;
  background-color: transparent;
  color: inherit;
}
.custom-datatable .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  color: inherit;
  min-width: 38px;
  line-height: 38px;
  text-align: center;
}
.custom-datatable .ngx-datatable .datatable-body-row, .custom-datatable .ngx-datatable .datatable-row-center, .custom-datatable .ngx-datatable .datatable-header-inner {
  display: -webkit-box !important;
}
.custom-datatable .ngx-datatable .datatable-footer .datatable-pager .pager {
  border: 1px solid #eff0f1;
  border-radius: 4px;
}

div.dt-button-background {
  display: none !important;
}

.dataTables_wrapper {
  padding: 0;
  width: 100%;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #e8ebf2;
}
.dataTables_wrapper .dt-buttons .btn {
  border-radius: 0.25rem !important;
}
.dataTables_wrapper .btn-group button {
  margin-right: 5px;
}
.dataTables_wrapper button {
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #ffffff;
}
.dataTables_wrapper + .dataTables_wrapper {
  margin-top: 20px;
}
.dataTables_wrapper .dataTables_paginate {
  margin-left: 15px !important;
  border: 1px solid #f8f8f9;
  border-radius: 0.25rem;
  padding-top: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #3b82f6;
  color: #ffffff !important;
  box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #3b82f6;
  color: #facd03 !important;
  background: transparent !important;
}
.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}
.dataTables_wrapper .dataTables_length select {
  border-color: #f8f8f9;
  color: #2a3142;
  padding: 0 10px;
  margin: 0 10px;
  height: 2.7142em;
}
.dataTables_wrapper table.dataTable {
  border: 1px solid #eff0f1;
}
.dataTables_wrapper table.dataTable thead th, .dataTables_wrapper table.dataTable thead td {
  border-bottom: 2px solid #eff0f1;
}
.dataTables_wrapper table.dataTable th, .dataTables_wrapper table.dataTable td {
  padding: 0.75rem;
}
.dataTables_wrapper .dataTables_filter {
  margin-bottom: 25px;
  margin-left: 15px;
}
.dataTables_wrapper .dataTables_filter input[type=search] {
  border: 1px solid #eff0f1;
  padding: 0 10px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
  width: 70%;
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

table .fixedHeader-floating {
  position: fixed !important;
  background-color: #ffffff;
}
table .box > div {
  padding: 5px 15px;
  background-color: #3b82f6;
  display: inline-block;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}
table.dataTable.fixedHeader-locked, table.dataTable.fixedHeader-floating {
  width: calc(100vw - 250px) !important;
  max-width: calc(100vw - 250px) !important;
  overflow: hidden !important;
  right: 0 !important;
  z-index: 99;
  left: 260px !important;
}
table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
  width: 100% !important;
}
table.dataTable {
  border-collapse: collapse !important;
  margin-top: 0 !important;
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px !important;
}
table.dataTable input, table.dataTable select {
  border: 1px solid #eff0f1;
  height: 37px;
}

.toolbar {
  padding: 5px 15px;
  background-color: #3b82f6;
  display: inline-block;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

code.option {
  color: #e27272 !important;
}
code.api {
  color: #81ba00 !important;
}

.dt-ext .dataTables_wrapper .page-item.active .page-link {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: #ffffff;
}
.dt-ext .dataTables_wrapper .dataTables_paginate {
  border: none;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  border: none;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.hover, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.active {
  border: none !important;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
  margin-left: 0px;
  color: #3b82f6;
}
.dt-ext .dataTables_wrapper button.dt-button, .dt-ext .dataTables_wrapper div.dt-button, .dt-ext .dataTables_wrapper a.dt-button, .dt-ext .dataTables_wrapper button.dt-button:focus:not(.disabled), .dt-ext .dataTables_wrapper div.dt-button:focus:not(.disabled), .dt-ext .dataTables_wrapper a.dt-button:focus:not(.disabled), .dt-ext .dataTables_wrapper button.dt-button:active:not(.disabled), .dt-ext .dataTables_wrapper button.dt-button.active:not(.disabled), .dt-ext .dataTables_wrapper div.dt-button:active:not(.disabled), .dt-ext .dataTables_wrapper div.dt-button.active:not(.disabled), .dt-ext .dataTables_wrapper a.dt-button:active:not(.disabled), .dt-ext .dataTables_wrapper a.dt-button.active:not(.disabled) {
  background-color: #3b82f6;
  border-color: #3b82f6;
  border-radius: 2px;
  color: #ffffff;
  background-image: none;
  font-size: 14px;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-success, .dt-ext .dataTables_wrapper div.dt-button.btn-success, .dt-ext .dataTables_wrapper a.dt-button.btn-success {
  background-color: #81ba00;
  border-color: #81ba00;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-primary, .dt-ext .dataTables_wrapper div.dt-button.btn-primary, .dt-ext .dataTables_wrapper a.dt-button.btn-primary {
  background-color: #3b82f6;
  border-color: #3b82f6;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-danger, .dt-ext .dataTables_wrapper div.dt-button.btn-danger, .dt-ext .dataTables_wrapper a.dt-button.btn-danger {
  background-color: #e27272;
  border-color: #e27272;
}
.dt-ext .dataTables_wrapper table.dataTable th.focus, .dt-ext .dataTables_wrapper table.dataTable td.focus {
  outline: 3px solid #3b82f6;
}
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected > .sorting_1, .dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
  background-color: #9dc0fa;
}
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd.selected, .dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd > .selected, .dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected, .dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd > .selected {
  background-color: #9dc0fa;
}
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.even.selected > .sorting_1, .dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
  background-color: #9dc0fa;
}
.dt-ext .dataTables_wrapper table.dataTable tbody > tr.selected, .dt-ext .dataTables_wrapper table.dataTable tbody > tr > .selected {
  background-color: #9dc0fa;
}

td.highlight {
  background-color: #f8f8f9 !important;
}

td.details-control {
  background: url("/images/details_open.png") no-repeat center center;
  cursor: pointer;
}

tr.shown td.details-control {
  background: url("/images/details_close.png") no-repeat center center;
}

.dataTables_scroll ~ .dataTables_paginate {
  margin-top: 20px !important;
}

.product-table th:last-child {
  min-width: 120px;
}
.product-table h6 {
  font-weight: 600;
  color: #2a3142;
}

table.dataTable.display tbody tr.odd > .sorting_1 {
  background-color: #f6f6f6;
}

/**=====================
     30. Data-table CSS Ends
==========================**/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue:#007bff;
  --indigo:#6610f2;
  --purple:#6f42c1;
  --pink:#e83e8c;
  --red:#dc3545;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:#fff;
  --gray:#6c757d;
  --gray-dark:#343a40;
  --primary:#007bff;
  --secondary:#6c757d;
  --success:#28a745;
  --info:#17a2b8;
  --warning:#ffc107;
  --danger:#dc3545;
  --light:#f8f9fa;
  --dark:#343a40;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.mark, mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td, .table-sm th {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-append > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #28a745;
}

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid ~ .custom-file-label::before, .was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit;
}

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid ~ .custom-file-label::before, .was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropdown, .dropup {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover {
  z-index: 1;
}

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .custom-file:focus, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append, .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
  margin-top: 0;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(2.25rem + 2px - 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container, .navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^=top] .arrow, .bs-popover-top .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^=right] .arrow, .bs-popover-right .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^=left] .arrow, .bs-popover-left .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.active.carousel-item-right, .carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-right, .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.active.carousel-item-left, .carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-left, .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}

.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1, .mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}

.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1, .py-1 {
  padding-top: 0.25rem !important;
}

.pr-1, .px-1 {
  padding-right: 0.25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}

.pr-2, .px-2 {
  padding-right: 0.5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2, .px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote, pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img, tr {
    page-break-inside: avoid;
  }
  h2, h3, p {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td, .table th {
    background-color: #fff !important;
  }
  .table-bordered td, .table-bordered th {
    border: 1px solid #ddd !important;
  }
}
