/* You can add global styles to this file, and also import other style files */
body{
    padding: 0;
    margin: 0;
}

eyeicon{
    right: 6% !important;
}

eyeslashicon{
    right: 6% !important;
}

.bad-high-init .p-datepicker {
    top: -170px !important;
}

.bad-high-end .p-datepicker{
    top: -220px !important;
}

.list-date-init .p-datepicker{
    top: -220px !important;
}

.list-date-end .p-datepicker{
    top: -220px !important;
    left: -221px !important;
}